import React from 'react';
import './edit-profile.scss';
import { Link } from 'react-router-dom';
import EditPhoto from './edit-photo/edit_photo';
import EditForm from './edit-form/edit_form';
import LeftSidebar from '../../components/shared/left-sidebar';
import Header from '../../components/shared/header';
import { request } from '../../constants/constants';
import UserContext from '../../contexts/user';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

class EditProfilePage extends React.Component {

	static contextType = UserContext;

	constructor(props) {
		super(props);
		this.handleCancel = this.handleCancel.bind(this);
		this.state = {
			isLoading: false
		}
	}

	handleCancel() {
		this.props.history.goBack();
	}

	handleSubmit = (data) => {
		this.setState({
			isLoading: true
		})
		request('PUT', `/auth/organisation/${this.context.user.current_org}/users/${this.state.user.id}`, data, true)
		.then(res => {
			console.log(res);
			const user = {
				...this.context.user,
				user: res.data.user
			}
			Cookies.set('user', user);
			this.setState({
				isLoading: false
			})
			Swal.fire(
				'Success!',
				'Profile updated successfully!',
				'success'
			).then(() => {
				console.log('reload')
				window.location.reload();
			})
		}).catch(err => {
			console.error(err);
			this.setState({
				isLoading: false
			})
			Swal.fire(
				'Error!',
				err.response.data.message,
				'error'
			)
		})
	}

	onSubmitPhoto = data => {
		this.setState({
			isLoading: true
		})
		request('PUT', `/auth/organisation/${this.context.user.current_org}/users/${this.state.user.id}/profile`, data, true)
		.then(res => {
			console.log(res);
			const user = {
				...this.context.user,
				user: res.data.user
			};
			Cookies.set('user', user);
			this.setState({
				isLoading: false
			})
			Swal.fire(
				'Success!',
				'Profile updated successfully!',
				'success'
			).then(() => {
				console.log('reload')
				window.location.reload();
			})
		}).catch(err => {
			console.error(err);
			this.setState({
				isLoading: false
			})
			Swal.fire(
				'Error!',
				err.response.data.message,
				'error'
			)
		})
	}

	componentDidMount() {
		this.setState({
			user: this.context.user.user
		})
	}

	render() {
		return (

			// <div>
			// 	<LeftSidebar />
			// 	<div className="main-panel">
			// 		<Header />
			// 		<div className="main-content">
			// 			<div className="container-fluid h-100">


							<div className="edit-profile">
								<div className="page-head mb_30">
									<div className="head">
										<h1 className="page-title">Edit Profile</h1>
										<nav>
											<ol className="breadcrumb page-title">
												<li className="breadcrumb-item"><Link to="/">Home</Link></li>
												<li className="breadcrumb-item current">Edit Profile</li>
											</ol>
										</nav>
									</div>
								</div>
								<div className="box">
									<div className="edit-profile-form">
										<div className="row">
											{
												this.state.user && (
													<React.Fragment>
														<EditPhoto profilePic={this.context.user.user.profile_pic_url} isLoading={this.state.isLoading}  onSubmitPhoto={this.onSubmitPhoto}/>
														<EditForm isLoading={this.state.isLoading} handleSubmit={this.handleSubmit} user={this.state.user} handleCancel={this.handleCancel} />
													</React.Fragment>
												)
											}
										</div>
									</div>
								</div>
							</div>
			// 			</div>
			// 		</div>
			// 	</div>
			// </div>
		);
	}
}

// export default withAuth(EditProfilePage,'administrator');
export default EditProfilePage;