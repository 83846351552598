import React, {Component} from 'react';
import PropTypes from 'prop-types';
import FormError from '../../../components/form-error/form-error';
import Loader from '../../../components/loader/loader';

export default class BankForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bankname: '',
            bsb: '',
            accountnumber: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentWillMount() {
        this.props.fetchBankDetail();
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.bank_details !== null && Object.keys(nextProps.bank_details).length > 0)
        {
            this.setState({
                bankname: nextProps.bank_details.bankname,
                bsb: nextProps.bank_details.BSB,
                accountnumber: nextProps.bank_details.accountnumber
            });
        }
    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }
    async handleSubmit(event) {
        event.preventDefault();
        const {
            bankname,
            bsb,
            accountnumber
        } = this.state;

        const data = {
            bankname: bankname,
            bsb: bsb,
            accountnumber: accountnumber
        }
        this.props.onSubmitDetail(data);
    }
    render () {
        let bankname_error = null;
        let bsb_error= null;
        let accountnumber_error= null;
        let other_error = null;
        if(this.props.errors !== undefined)
        {
            for (var key in this.props.errors) {
                if(key === 'bankname')
                    bankname_error = <FormError error={this.props.errors[key][0]} />;
                else if(key === 'bsb')
                    bsb_error = <FormError error={this.props.errors[key][0]} />;
                else if(key === 'accountnumber')
                    accountnumber_error = <FormError error={this.props.errors[key][0]} />;
                else
                    other_error = <FormError error={this.props.errors[key][0]} />
            }
        }
        return (
            <div className="bank-details">
                {this.props.loading && <Loader />}
                <div className="modal-body">
                    <div className="row">
                        {other_error}
                        <div className="col-md-6 col-12">
                            <div className="form-group">
                                <label className="label">Bank Name</label>
                                <div className="input-effect-1">
                                    <input type="text" name="bankname" value={this.state.bankname} className="form-control" onChange={event => this.handleInputChange(event)} />
                                    <span className="focus-border"></span>
                                </div>
                                {bankname_error}
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="form-group">
                                <label className="label">BSB Number</label>
                                <div className="input-effect-1">
                                    <input type="text" name="bsb" value={this.state.bsb} className="form-control" onChange={event => this.handleInputChange(event)} />
                                    <span className="focus-border"></span>
                                </div>
                                {bsb_error}
                            </div>
                        </div>
                        <div className="col-md-6 col-12">
                            <div className="form-group">
                                <label className="label">Account Number</label>
                                <div className="input-effect-1">
                                    <input type="text" name="accountnumber" value={this.state.accountnumber} className="form-control" onChange={event => this.handleInputChange(event)} />
                                    <span className="focus-border"></span>
                                </div>
                                {accountnumber_error}
                            </div>
                        </div>
                        {/* <div className="col-md-6 col-12">
                            <div className="form-group">
                                <label className="label">Account Name</label>
                                <div className="input-effect-1">
                                    <input type="text" className="form-control" name="name" />
                                    <span className="focus-border"></span>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="modal-footer">
                    <button className="btn btn-orange" onClick={this.handleSubmit}>Save</button>
                </div>
            </div>
        );
    }
}

BankForm.propTypes = {
    onSubmitDetail: PropTypes.func.isRequired,
    fetchBankDetail: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    errors: PropTypes.object
}