import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { request } from '../../constants/constants';
import UserContext from '../../contexts/user';
import Swal from 'sweetalert2';
import history from '../../history';
import Pagination from '../../components/pagination/pagination';
import ReactTable from 'react-table';
import Modal from 'react-responsive-modal';
import Loader from '../../components/loader/loader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

class EditUser extends Component {

    static contextType = UserContext;
    userId;

    constructor(props) {
        super(props);
        this.state = {
            permissions: [],
            administrator: false,
            upload: false,
            teams: [],
			selectedTeams: [],
			currentSelectedTeams: [],
			modal: false,
			selected: {},
			pageSize: 10,
			currentPage: 1,
			selectedAll: {},
			indeterminateSign: false,
			isLoading: false,
			gender: [
				'Men',
				'Women',
				'Boys',
				'Girls',
				'Mixed'
			],
			search: ''
        }
    }

    onChange = e => {
        console.log(e.target.name)
        this.setState({
            [e.target.name]: e.target.checked
        })
    }
    

    handleSubmit = e => {
        this.setState({
            isLoading: true
        })
        const teamIds = [];
        Object.keys(this.state.selected).forEach(key => {
           if(this.state.selected[key]) teamIds.push(key); 
        });
        const data = {
            administrator: this.state.administrator,
            upload: this.state.upload,
            teams: teamIds
        }
        request('PUT', `/auth/organisation/${this.context.user.current_org}/users/${this.userId}/permissions`, data, true)
        .then(res => {
            this.setState({isLoading: false})
            console.log(res);
            Swal.fire(
                'Success!',
                'User Permissions updated successfully.',
                'success'
            ).then(() => history.push('/users'));
        }).catch(err => {
			console.error(err.response);
            this.setState({
                isLoading: false
			})
			Swal.fire(
				'Error',
				err.response.data.message,
				'error'
			)
        })
    }
    showModal = () => {
		this.setState({
			modal: true
		})
	}
	closeModal = () => {
		this.setState({
			modal: false
		})
	}

	toggleSelectAll() {
		let newSelected = {};
		let newSelectPage;
		const start = (this.state.currentPage - 1) * this.state.pageSize;
		const end = start + this.state.pageSize;
		if (this.state.selectedAll[this.state.currentPage] === false || this.state.selectedAll[this.state.currentPage] == null || this.state.indeterminateSign === true) {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true});
			this.state.teams.slice(start, end).forEach(data => {
				newSelected[data.id] = true;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			// const selectedLeagues = Object.keys(newObj).filter(obj => {
			// 	return newObj[obj] === true;
			// });
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage,
				indeterminateSign: false,
				currentSelectedTeams: [...this.state.teams]
			});
		} else {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage] : false });
			this.state.teams.slice(start, end).forEach(data => {
				newSelected[data.id] = false;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			// const selectedGames = Object.keys(newObj).filter(obj => {
			// 	return newObj[obj] === true;
			// });

			// this.props.selectedGames(selectedGames);
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage,
				currentSelectedTeams: []
			});
		}
	}
	toggleRow(team) {
		const teamId = team.id;
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[teamId] = !this.state.selected[teamId];

		const index = this.state.currentSelectedTeams.findIndex(element => element.id == team.id);
		if(index < 0) {
			this.state.currentSelectedTeams.push(team);
		} else {
			this.state.currentSelectedTeams.splice(index, 1);
		}

		this.setState({
			currentSelectedTeams: this.state.currentSelectedTeams,
			selected: newSelected
		});
	}

	handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				if (this.state.selectedAll[this.state.currentPage] === true) {
					this.setState({
						indeterminateSign: true
					})
				}
			});
		} else {
			this.setState({ pageSize: data }, () => {
				const start = (this.state.currentPage - 1) * this.state.pageSize;
				const end = start + this.state.pageSize;
				let newSelected = {};
				let newSelectPage;
				if (this.state.selectedAll[this.state.currentPage] === true) {
					newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
					this.state.teams.forEach(data => {
						newSelected[data.id] = false;
					});
					this.state.teams.slice(start, end).forEach(data => {
						newSelected[data.id] = true;
					});
					let newObj = Object.assign(this.state.selected, newSelected);
					const selectedList = Object.keys(newObj).filter(obj => {
						return newObj[obj] === true;
					});
					// this.props.selectedList(selectedList);
					this.setState({
						selected: newObj,
						selectedAll: newSelectPage,
						indeterminateSign: false
					});
				}
			});
        }
	}
	
	getAllTeams() {
		return request(
			'GET',
			`/auth/organisation/${this.context.user.current_org}/teams`,
			{},
			true
		)
	}

	handleAdd = () => {
		this.state.selectedTeams = [...this.state.selectedTeams, ...this.state.currentSelectedTeams];
		this.closeModal();
	}

    // onChange = e => {
    //     if(e.target.checked) {
    //         this.setState({
    //             permissions: [...this.state.permissions, e.target.value]
    //         })
    //     } else {
    //         const permissions = this.state.permissions;
    //         const index = permissions.indexOf(e.target.value);
    //         if(index > -1) {
    //             permissions.splice(index,1);
    //         }
    //         this.setState({
    //             permissions
    //         })
    //     }
    // }

    componentDidMount() {
        this.setState({
            isLoading: true
        })
        const { match: { params } } = this.props;
        this.userId = params.id;
        this.getAllTeams().then(res => {
            console.log(res)
            this.setState({
                isLoading: false,
                teams: res.data.data
            })
        }).catch(err => {
            console.error(err);
            this.setState({
                isLoading: false
            })
        })

        console.log(this.userId)
    }

    render() {
		let data = this.state.teams;
		if (this.state.search) {
			data = data.filter(row => {
				return row.team_name.toLowerCase().includes(this.state.search.toLowerCase());
			})
		}
		const columns = [
			{
				Header: (header) => (
					<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
						<input
							type="checkbox"
							className="custom-control-input"
							id="selectAllCheckBox"
							checked={this.state.selectedAll[this.state.currentPage] || false}
							onChange={() => this.toggleSelectAll()}
							ref={input => {
								if (input) {
									input.indeterminate = this.state.indeterminateSign;
								}
							}}
						/>
						<label className="custom-control-label" htmlFor="selectAllCheckBox"></label>
					</div>
				),
				width: 52,
				Cell: row => (
					<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
						<input type="checkbox" className="custom-control-input" id={`game-id-${row.original.id}`} checked={this.state.selected[row.original.id] || false} onChange={() => this.toggleRow(row.original)} />
						<label className="custom-control-label" htmlFor={`game-id-${row.original.id}`}></label>
					</div>
				),
				className: 'header-checkbox-toggle',
				headerStyle: { padding: '1.07143em 0' },
				style: { padding: '0.71429em 0' }
				
			},
			{
				Header: 'Name',
				minWidth: 250,
				accessor: 'team_name'
			},
			{
				Header: 'League',
				Cell: row => (
					<span>{row.original.league && row.original.league.league_name}</span>
				),
			},
		
		]
        return (
            <div className="edit-user">
                <Modal
					open={this.state.modal}
					onClose={this.closeModal}
					showCloseIcon={false}
					center
					classNames={{
						modal: "modal fade show auto-width-modal"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Select Team(s)</h5>
								<button type="button" className="close" onClick={this.closeModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								{this.props.modalLoader && <Loader />}
								<div className="row">
									<div className="col">
										<div className="table-area">
											
											{(this.state.isLoading) && <Loader />}
												<ReactTable
													PaginationComponent={Pagination}
													data={data}
													minRows={5}
													columns={columns}
													noDataText={this.state.isLoading ? '' : "No Teams Found."}
													defaultPageSize={this.state.pageSize}
													pageSizeOptions={[10, 25, 50, 100]}
													// resizable={true}
													onPageChange={(index) => this.handlePageChange(index)}
													onPageSizeChange={(size) => this.handlePageSizeChange(size)}
													getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
												/>
										</div>
									</div>
								</div>
							</div>

							<div className="modal-footer text-center">
								<button className="btn btn-orange" onClick={this.handleAdd}>Add</button>
							</div>
						</div>
					</div>
				</Modal>
                <div className="page-head mb_30">
                    <div className="head">
                        <h1 className="page-title" >Edit User</h1>
                        <nav>
                            <ol className="breadcrumb page-title">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/users">Users</Link></li>
                                <li className="breadcrumb-item current"> Edit User</li>
                            </ol>
                        </nav>
                    </div>
                </div>
                <div className="box">
                    <div className="row">
                        <div className="col">
                            <div className="form-group">    
                                <label className="label">Permission</label>
                                <div className="input-effect-1">
                                    <div>
                                        <div className="custom-control custom-checkbox d-inline">
                                            <input onChange={this.onChange} name="administrator" checked={this.state.administrator} type="checkbox" className="custom-control-input" id="administratorCheck" />
                                            <label className="custom-control-label" htmlFor="administratorCheck">Administrator</label>
                                        </div>
                                        <div className="custom-control custom-checkbox d-inline">
                                            <input onChange={this.onChange} name="upload" checked={this.state.upload} type="checkbox" className="custom-control-input" id="uploadCheck"/>
                                            <label className="custom-control-label" htmlFor="uploadCheck">Upload</label>
                                        </div>
                                    </div>

                                    <div className="focus-border" /></div>
                            </div>
                        </div>
                    </div>
					{
						this.state.upload && (
						<div className="row">
							<div className="col">
							<label className="label">Team(s)</label>
								<div className="table-area mb-4">
								<div className="heading mb_20">
									<div className="dflex justify-content-end">
										<div className="search-table">
											<div className="input-group">
												<input type="text" value={this.state.search} onChange={e => this.setState({ search: e.target.value })} className="form-control" placeholder="Search" />
												<div className="input-group-append">
													<button className="input-group-text"><FontAwesomeIcon icon={faSearch} className="fa fa-search" /></button>
												</div>
											</div>
										</div>
									</div>
								</div>
									{(this.state.isLoading) && <Loader />}
										<ReactTable
											PaginationComponent={Pagination}
											data={data}
											minRows={5}
											columns={columns}
											noDataText={this.state.isLoading ? '' : "No Teams Found."}
											defaultPageSize={this.state.pageSize}
											pageSizeOptions={[10, 25, 50, 100]}
											// resizable={true}
											onPageChange={(index) => this.handlePageChange(index)}
											onPageSizeChange={(size) => this.handlePageSizeChange(size)}
											getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
										/>
								</div>
							</div>
						</div>
						)
					}
                    <div className="action text-center">
                        <button className="btn btn-orange mr-4" onClick={this.handleSubmit}>
                            {
                                this.state.isLoading ?  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : 'Submit'
                            }
                         
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditUser;