import React, {Component} from 'react';
// import PropTypes from 'prop-types';
// import Loader from '../../../../loader/loader';
// import FormError from '../../../../form-error/form-error';

export default class ResetPassword extends Component {
    render () {
        return (
            <div>
                <div className="modal-body">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label className="label">Old Password</label>
                                <div className="input-effect-1">
                                    <input type="text" name="old_password" value="" className="form-control" />
                                    <div className="focus-border"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="label">New Password</label>
                                <div className="input-effect-1">
                                    <input type="text" name="new_password" value="" className="form-control" />
                                    <div className="focus-border"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="form-group">
                                <label className="label">Confirm New Password</label>
                                <div className="input-effect-1">
                                    <input type="text" name="confirm_new_password" value="" className="form-control" />
                                    <div className="focus-border"></div>
                                </div>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="modal-footer">
                    <button className="btn btn-orange">Reset</button>
                </div> 
            </div>
        );
    }
}