import React, { Component } from 'react';
import AssignReferee from './assign-referee/assign_referee';
import axios from 'axios';
import swal from 'sweetalert2/dist/sweetalert2.js';
import { API_URL } from '../../../../../constants/constants';
// import { push } from 'connected-react-router';

class ListReferee extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showReferee: false,
			fields: [],
			loading_field: false,
			loading_referee: false,
			loading_assignref: false,
			referees: {},
			assign_referees: {}
		};
		this.showReferee = this.showReferee.bind(this);
		this.fetchAssignReferee = this.fetchAssignReferee.bind(this);
	}

	fetchAssignReferee = (id) => {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;

		this.setState({
			loading_assignref: true
		});

		axios.get(`${API_URL}/v1/auth/organisation/${organisation_id}/games/${id}/referees`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		).then(response => {
			this.setState({
				assign_referees: response.data.data,
				loading_assignref: false
			})
		}).catch(error => {
				if(error.response.status === 401) {
					localStorage.removeItem('user');
					swal(
						'Error',
						'Your session has expired.',
						'error'
					);
					// push('/login');
				}
				console.log(error);
		});
	}

	showReferee = (id) => {
		this.setState({
			showReferee: true,
			loading_field: true,
			loading_referee: true
		});

		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;

		axios.get(`${API_URL}/v1/auth/organisation/${organisation_id}/games/${id}`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		).then(response => {
			this.setState({
				fields: response.data.data.fields,
				loading_field: false
			})
		}).catch(error => {
				if(error.response.status === 401) {
					localStorage.removeItem('user');
					swal(
						'Error',
						'Your session has expired.',
						'error'
					);
					// push('/login');
				}
				console.log(error);
		});

		axios.get(`${API_URL}/v1/auth/organisation/${organisation_id}/games/${id}/availableofficials`,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		).then(response => {
			this.setState({
				referees: response.data.data,
				loading_referee: false,
			})
		}).catch(error => {
				if(error.response.status === 401) {
					localStorage.removeItem('user');
					swal(
						'Error',
						'Your session has expired.',
						'error'
					);
					// push('/login');
				}
				console.log(error);
		});
		this.fetchAssignReferee(id);
	}

	onAssignOfficial = (data, id) => {
		let user = JSON.parse(localStorage.getItem('user'));
		let organisation_id = user.current_org;
		this.setState({
			loading_assignref: true
		});

		axios.put(`${API_URL}/v1/auth/organisation/${organisation_id}/games/${id}/referees`,
			data,
			{
				headers: {
					'Content-Type': 'application/json',
					'X-Requested-With': 'XMLHttpRequest',
					'Access-Control-Allow-Origin': '*',
					'Authorization': 'Bearer ' + user.access_token,
				}
			}
		).then(response => {
			swal(
				'Success',
				response.data.message,
				'success'
			);
			this.fetchAssignReferee(id);
		}).catch(error => {
			this.setState({
				loading_assignref: false
			});
				if(error.response.status === 401) {
					localStorage.removeItem('user');
					swal(
						'Error',
						'Your session has expired.',
						'error'
					);
					// push('/login');
				}
				console.log(error);
		});

	}

	loadingStatus() {
		if (this.state.loading_field || this.state.loading_referee || this.state.loading_assignref) {
			return true;
		} else {
			return false;
		}
	}

	render() {
		let loadingStatus = this.loadingStatus();
		return (
			!this.state.showReferee
				? <button className="btn btn-white" onClick={() => this.showReferee(this.props.row.original.id)}>Assign Referee</button>
				: <AssignReferee
						fields={this.state.fields}
						game_id={this.props.row.original.id}
						loading={loadingStatus}
						referees={this.state.referees}
						assign_referees={this.state.assign_referees}
						onAssignOfficial={this.onAssignOfficial}
					/>
		);
	}
}

export default ListReferee;