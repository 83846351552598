import React, { Component } from 'react';
import Loader from '../../components/loader/loader';
import * as AWS from 'aws-sdk';
import { request } from '../../constants/constants';
import Usercontext from '../../contexts/user';

class Download extends Component {

    s3Client
    static contextType = Usercontext;

    constructor(props) {
        super(props);
        AWS.config.region = 'ap-southeast-2'; // Region
		AWS.config.update({
			accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
			secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: process.env.REACT_APP_AWS_DEFAULT_REGION,
            maxRetries: 2,
            httpOptions: {
                timeout:  1800000000,
                // connectTimeout: 5000
            }
		});
		this.s3Client = new AWS.S3();
        this.state = {

        }
    }

    getGameDetails() {
		return request(
			'GET',
			`/auth/organisation/${this.context.user.current_org}/games/${this.props.match.params.id}`,
			{},
			true
		)
    }
    
    getGameVideoPath() {
        return request(
            'GET',
            `/auth/organisation/${this.context.user.current_org}/games/${this.props.match.params.id}/video-path`,
            {},
            true
        )
    }
    

    componentDidMount() {
        this.getGameVideoPath().then(res => {
            console.log(res);
            const path =  res.data.path;
            var params = {
                Bucket: process.env.REACT_APP_AWS_BUCKET, 
                Key: path,
                Expires: 10800, 
                // Range: "bytes=0-10000000"
            };

            
            this.s3Client.getSignedUrl('getObject', params, function (err, url) {
                console.log('The URL is', url);

                const a = document.createElement('A');
                a.href = url;
                a.download = url.substr(url.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                // window.close();
                // window.open(url);
                // window.close();
                // var a = document.createElement("a");
                // // var blob = new Blob([data.Body], { type: data.ContentType });
                // // var URL = window.URL || window.webkitURL;
                // var downloadUrl = URL.createObjectURL(url);
                //           // safari doesn't support this yet
                //           if (typeof a.download === 'undefined') {
                //               window.location = downloadUrl;
                //           } else {
                //               a.href = downloadUrl;
                //               a.download = path.split('/')[3];
                //               document.body.appendChild(a);
                //               a.click();
                //             //   window.close();
                //           }
              });
        //    const req = this.s3Client.getObject(params ).on('httpDownloadProgress', (evt) => {
		// 		console.log('Progress:', evt.loaded, '/', evt.total); 
		// 		this.setState({
		// 			progress: (evt.loaded/evt.total)*100,
		// 			percentage: Math.floor((evt.loaded/evt.total)*100),
		// 		})
		// 	}).send((err, data) => { 
        //         if(err) {
        //             console.log(err) 
        //         }
        //         var a = document.createElement("a");
        //         var blob = new Blob([data.Body], { type: data.ContentType });
        //         var URL = window.URL || window.webkitURL;
        //         var downloadUrl = URL.createObjectURL(blob);
        //                   // safari doesn't support this yet
        //                   if (typeof a.download === 'undefined') {
        //                       window.location = downloadUrl;
        //                   } else {
        //                       a.href = downloadUrl;
        //                       a.download = path.split('/')[3];
        //                       document.body.appendChild(a);
        //                       a.click();
        //                     //   window.close();
        //                   }
		// 	});
        })
    }

    render() {
        return (
            <div className="text-center" style={{position: 'relative', minHeight: '77vh'}}>
                <p>Please wait your file is downloading, Don't close the browser, It will automatically close when finished.</p>
                <Loader/>
            </div>
        );
    }
}

export default Download;