import React, { Component } from 'react';
import './acceptInviteUser.scss';
import LogoBackground from '../../components/logo/LogoBackground';
import Loader from '../../components/loader/loader';
import Logo from '../../components/logo/Logo';
import passwordSvg from '../../public/svg/password.svg';
import { request } from '../../constants/constants';
import Swal from 'sweetalert2';
import history from '../../history';

class AcceptInviteUser extends Component {

    token;
    
    constructor(props) {
        super(props);
        const params = new URLSearchParams(this.props.location.search);
        this.token = params.get('token');
        console.log(this.token)
        this.state = {
            password: '',
            password_confirmation: '',
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
          [name]: value
        });
    }

    handleSubmit = e => {
        const data = {
            accept_token: this.token,
            password: this.state.password,
            password_confirmation: this.state.password_confirmation
        }

        request('POST', '/auth/accept', data).then(res => {
            console.log(res);
            Swal.fire(
                'Success!',
                'Confirmed Successfullt!',
                'success'
            ).then(() => history.push('/login'));
        }).catch(err => {
            Swal.fire(
                'Error!',
                err.response.data.message,
                'error'
            )
            console.error(err.response);
        })
    }

    render() {
        return (
            <div>
            {this.props.loading && <Loader />} 
             <div className="authentication">
                 <LogoBackground />
                 <div className="login-container">
                     <div className="login-wrap">
                         <Logo />
                         <form>
                             {/* {other_error} */}
                             <div className="signin">
                                 <label className="label">New Password</label>
                                 <div className="form-group">
                                     <div className="input-group">
                                         <div className="input-group-prepend">
                                             <span className="input-group-text">
                                                 <span className="icon icon-pass">
                                                     <img src={passwordSvg} className="imgsvg" alt="password" />
                                                 </span>
                                             </span>
                                         </div>
                                         <input type="password" name="password" className="form-control" placeholder="Password" onChange={event => this.handleInputChange(event)} />
                                     </div>
                                 </div>
                                 {/* {password_error} */}
                             </div>
                             <div className="signin">
                                 <label className="label">Confirm Password</label>
                                 <div className="form-group">
                                     <div className="input-group">
                                         <div className="input-group-prepend">
                                             <span className="input-group-text">
                                                 <span className="icon icon-pass">
                                                     <img src={passwordSvg} className="imgsvg" alt="password" />
                                                 </span>
                                             </span>
                                         </div>
                                         <input type="password" name="password_confirmation" className="form-control" placeholder="Confirm Password" onChange={event => this.handleInputChange(event)} />
                                     </div>
                                 </div>
                             </div>
                             <div className="action">
                                 <input type="button" name="signin" value="Save" className="submit" onClick={this.handleSubmit} />
                             </div>
                         </form>
                     </div>
                 </div>
             </div>
         </div>
        );
    }
}

export default AcceptInviteUser;