import React, { Component } from 'react';
import LeftSidebar from '../../components/shared/left-sidebar';
import Header from '../../components/shared/header';
import Loader from '../../components/loader/loader';
import queryString from 'query-string';
import Swal from 'sweetalert2';
import history from '../../history';
import { request } from '../../constants/constants';
import UserContext from '../../contexts/user';
import Cookies from 'js-cookie';

class efiicialTechCallback extends Component {

    static contextType = UserContext;

    componentDidMount() {
        const { location: { search } } = this.props;
        const queryParams = queryString.parse(search);
        console.log('queryParams', queryParams);
        if(queryParams.state && queryParams.authorisation_code && queryParams.organisation_id) {
            console.log(queryParams);
            request('GET',`/auth/integrations/efficialtec/organisations/${this.context.user.current_org}/connect?authorisation_code=${queryParams.authorisation_code}&efficialtec_organisation=${queryParams.organisation_id}`, {}, true)
            .then(res => {
                console.log(res);
                const user = {
                    ...this.context.user,
                    user: res.data.user
                }
                Cookies.set('user', user);
                Swal.fire(
                    'Success!',
                    'efficialtec has been successfully connected!',
                    'success'
                ).then(() => {
                    // window.location = '/integrations/efficialtec';
                })
            }).catch(err => {
                console.error(err.response)
            })
        } else {
            Swal.fire(
                'Error',
                'Invalid Page!',
                'error'
            ).then(() => {
                history.push('/');
            })
        }
    }

    render() {
        return (
            <div></div>
        );
    }
}

export default efiicialTechCallback;