import React, { useState, useEffect } from 'react';
import Routes from './routes/Routes';
import { Router } from 'react-router-dom';
import './public/styles/app.scss';
import 'bootstrap/dist/js/bootstrap';
import history from './history';
import { UserProvider } from './contexts/user';
import Cookies from 'js-cookie';
import LeftSidebar from './components/shared/left-sidebar';
import Header from './components/shared/header';
// import Intercom from 'react-intercom';

function App() {
  const userOnCookie = Cookies.getJSON('user');
  const [user, setUser] = useState(userOnCookie);
  useEffect(() => {
    setUser(userOnCookie)
  })
  // let userIntercom = {
  //   user_id: '',
  //   email: '',
  //   name: ''
  // };

  // if (user) {
  //   userIntercom = {
  //       user_id: user.user.intercom_id,
  //       email: user.user.email,
  //       name: user.user.name
  //     }
  // }
  return (
    <Router history={history}>
      <UserProvider value={{ user, setUser }}>
        <div className="App">
          <div className="wrapper">
            <div className="wrapper-inner">
              { user && <LeftSidebar /> }
              <div className={'main-panel ' + ( !user && 'auth' )}>
                { user && <Header /> }
                <div className="main-content">
                  <div className="container-fluid h-100">
                    <Routes />
                  </div>
                </div>
              </div>
            </div>
            {/* { user && <Intercom appID="wt440dsi" {...userIntercom} /> } */}
          </div>
        </div>
      </UserProvider>
    </Router>
  );
}

export default App;
