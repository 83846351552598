import React, { Component } from 'react';
import Logos from '../../public/images/small-logo.png';
import dashboardSvg from '../../public/svg/dashboard.svg';
import gamesSvg from '../../public/svg/games.svg';
import settingsSvg from '../../public/svg/settings.svg';
import organisationSvg from '../../public/svg/organisation.svg';
import leaguesSvg from '../../public/svg/leagues.svg';
import usersSvg from '../../public/svg/users.svg';
import venuesSvg from '../../public/svg/venues.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { NavLink } from 'react-router-dom';
import '../../public/styles/leftsidebar.scss';
import $ from 'jquery';
import ReactResizeDetector from 'react-resize-detector';
import Logo from '../logo/Logo';
import UserContext from '../../contexts/user';

export default class LeftSidebar extends Component {

	static contextType = UserContext;

	getSettings() {
		$('.imgsvg').each(function () {
			var $img = $(this);
			var imgID = $img.attr('id');
			var imgClass = $img.attr('class');
			var imgURL = $img.attr('src');
			$.get(imgURL, function (data) {
				var $svg = $(data).find('svg');
				if (typeof imgID !== 'undefined') {
					$svg = $svg.attr('id', imgID);
				}
				if (typeof imgClass !== 'undefined') {
					$svg = $svg.attr('class', imgClass + ' replaced-svg');
				}
				$svg = $svg.removeAttr('xmlns:a');
				if (!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
					$svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
				}
				$img.replaceWith($svg);
			}, 'xml');
		});

		const toggleStateParent = () => {
			// this.props.toggle();
		};


		function leftmenu() {
			if ($(window).width() > 767) {
				// desktop
				$('.main').addClass('desktop').removeClass('mobile left-open');
				$('.desktop .header-container .toggle').click(function (e) {
					e.preventDefault();
					$('.main').toggleClass('left-mini');
					toggleStateParent();
				});
			} else {
				// mobile
				$('.main').addClass('mobile').removeClass('desktop left-mini');
				$('.mobile .header-container .toggle').click(function () {
					$('.main').addClass('left-open');
				});
				$('.mobile .leftmenu li a.link').on('click', function () {
					if ($('.mobile .leftmenu li a.link').hasClass('active')) {
						setTimeout(function () {
							$('.main').removeClass('left-open');
						}, 500);
					}
				});
				$(document).on('touchstart click', function (e) {
					var closearea = $(".mobile .leftsidebar, .mobile .header-container .toggle");
					if (closearea.has(e.target).length === 0) {
						$('.main').removeClass('left-open');
					}
				});
			}
		}
		$(document).ready(function () {
			leftmenu();
		});
		$(window).resize(function () {
			leftmenu();
		});
	}
	componentDidMount() {
		this.getSettings();
	}
	render() {
		const currentOrg = this.context.user.current_org;
		const currentOrgIndex = this.context.user.organisations.findIndex(element => element.org_id === currentOrg);
		const permissions = this.context.user.organisations[currentOrgIndex].permissions;

		return (
			<div className="leftsidebar">
				<div className="wrappers">
					<div className="main-logo">
						<div className="dflex align-items-center h-100">
							<Logo />
							<div className="small-logo">
								<span><img src={Logos} alt="logo" /></span>
							</div>
						</div>
					</div>
					<div className="leftmenu" id="accordion">
						<ul>
							<li className="level_0">
								<NavLink exact={true} to="/" activeClassName='active' className="link">
									<span className="icon icon-dashboard">
										<img src={dashboardSvg} className="imgsvg" alt="dashboard" />
									</span>
									<span className="text">Dashboard</span>
								</NavLink>
							</li>
						
							<li className="level_0">
								<NavLink to="/games" activeClassName='active' className="link">
									<span className="icon icon-games">
										<img src={gamesSvg} className="imgsvg" alt="games" />
									</span>
									<span className="text">Games</span>
									{/* <span className="icon icon-down-arrow"><FontAwesomeIcon icon={faChevronDown} className="fa" /></span> */}
								</NavLink>
								
								{/* <div className="collapse sub-menu" id="game_collapse_1" data-parent="#accordion">
									<ul>
										<li><NavLink to="/games" activeClassName='active' className="link">All Games</NavLink></li>
									</ul>
								</div> */}
							</li>
							{
								permissions.administrator && (
									<React.Fragment>
										<li className="level_0">
											<NavLink exact={true} to="/leagues" activeClassName='active' className="link">
												<span className="icon icon-dashboard">
													<img src={leaguesSvg} className="imgsvg" alt="leagues" />
												</span>
												<span className="text">Leagues</span>
											</NavLink>
										</li>
										<li className="level_0">
											<NavLink exact={true} to="/teams" activeClassName='active' className="link">
												<span className="icon icon-dashboard">
													<img src={organisationSvg} className="imgsvg" alt="teams" />
												</span>
												<span className="text">Teams</span>
											</NavLink>
										</li>
										<li className="level_0">
											<NavLink exact={true} to="/venues" activeClassName='active' className="link">
												<span className="icon icon-dashboard">
													<img src={venuesSvg} className="imgsvg" alt="venues" />
												</span>
												<span className="text">Venues</span>
											</NavLink>
										</li>

										<li className="level_0">
											<a href="#/" data-target="#users_collapse_1" className="collapsed" data-toggle="collapse">
												<span className="icon icon-settings">
													<img src={usersSvg} className="imgsvg" alt="users" />
												</span>
												<span className="text">Users</span>
												<span className="icon icon-down-arrow"><FontAwesomeIcon icon={faChevronDown} className="fa" /></span>
											</a>
											<div className="collapse sub-menu" id="users_collapse_1" data-parent="#accordion">
												<ul>
													{/* {permissions.some(p => ['administrator', 'allocator'].includes(p)) && <li><NavLink to="/games" activeClassName='active' className="link">All Games</NavLink></li>} */}
													<li><NavLink exact to="/users" activeClassName='active' className="link">Active Users</NavLink></li>
													<li><NavLink exact to="/users/pending" activeClassName='active' className="link">Pending Users</NavLink></li>
												</ul>
											</div>
										</li>

										<li className="level_0">
											<a href="#/" data-target="#settings_collapse_1" className="collapsed" data-toggle="collapse">
												<span className="icon icon-settings">
													<img src={settingsSvg} className="imgsvg" alt="settings" />
												</span>
												<span className="text">Settings</span>
												<span className="icon icon-down-arrow"><FontAwesomeIcon icon={faChevronDown} className="fa" /></span>
											</a>
											<div className="collapse sub-menu" id="settings_collapse_1" data-parent="#accordion">
												<ul>
													{/* {permissions.some(p => ['administrator', 'allocator'].includes(p)) && <li><NavLink to="/games" activeClassName='active' className="link">All Games</NavLink></li>} */}
													{/* {permissions.some(p => ['official'].includes(p)) && <li><NavLink to="/mygames" activeClassName='active' className="link">My Games</NavLink></li>} */}
													<li><NavLink exact to="/integrations" activeClassName='active' className="link">Integrations</NavLink></li>
													{
														this.context.user.user.efficialtec_connected &&  (
															<li><NavLink exact to="/integrations/efficialtec" activeClassName='active' className="link">Efficialtec</NavLink></li>
														)
													}
												</ul>
											</div>
										</li>
									</React.Fragment>
								)
							}

						
						</ul>
					</div>
				</div>
				<ReactResizeDetector handleWidth refreshMode="debounce" refreshRate={100} onResize={this.props.onResize} />
			</div>
		);
	}
}