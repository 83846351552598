import React, { Component } from 'react';
import LeftSidebar from '../../components/shared/left-sidebar';
import Header from '../../components/shared/header';
import { Link } from 'react-router-dom';
import Switch from "react-switch";
import efficialtecLogo from '../../public/images/efficialtec_logo.png';
import './integrations.scss';
import history from '../../history';
import UserContext from '../../contexts/user';
import { request } from '../../constants/constants';
import Loader from '../../components/loader/loader';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

class IntegrationsPage extends Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
			efficialTec: false,
		};
    }

    handleChange = (checked, event, id) => {
        console.log(id, checked);
        // this.setState({
        //     [id]: checked
        // })
        if(id === "efficialTec") {
          if(checked) {
            this.setState({
                [`${id}IsLoading`] : true
            })
                // history.push('oauth/efficialtec/callback');
            request('GET', `/auth/integrations/efficialtec/organisations/${this.context.user.current_org}/efficialtecURL`, {}, true)
            .then(res => {
                console.log(res);
                window.location = res.data.url;
            }).catch(err => {
                console.error(err);
                Swal.fire(
                    'Error',
                    err.response.data.message,
                    'error'
                )
            })
          } else {
              Swal.fire({
                title: 'Disconnect efficialtec?',
                text: "You won't be able to revert this!",
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: '#f15a24',
                // cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, do it!'
              }).then(result => {
                if (result.value) {
                    request('GET', `/auth/integrations/efficialtec/organisations/${this.context.user.current_org}/disconnect`, {}, true)
                    .then(res => {
                        console.log(res);
                        const user = {
                            ...this.context.user,
                            user: res.data.user
                        }
                        Cookies.set('user', user);
                        Swal.fire(
                            'Disconnect!',
                            'efficialtec has been disconnected.',
                            'success'
                        ).then(() => {
                            window.location.reload();
                        })
                    }).catch(err => {
                        Swal.fire(
                            'Error',
                            err.response.data.message,
                            'error'
                        )
                        console.error(err.response)
                    })            
                  }
              }).catch(err => {
                Swal.fire(
                    'Error',
                    err.response.data.message,
                    'error'
                )
                console.error(err.response)
            })  
          }
        }
    }

    render() {
        return (
            // <div>
            //     <LeftSidebar />
            //     <div className="main-panel">
            //         <Header />
            //         <div className="main-content">
            //             <div className="container-fluid h-100">

                            <div className="integration-page">
                                {
                                     this.state.efficialTecIsLoading && <Loader /> 
                                }
								<div className="page-head mb_30">
									<div className="head">
										<h1 className="page-title" onClick={this.props.showLoader}>Integrations</h1>
										<nav>
											<ol className="breadcrumb page-title">
												<li className="breadcrumb-item"><Link to="/">Home</Link></li>
												<li className="breadcrumb-item current">Integrations</li>
											</ol>
										</nav>
									</div>
									<div className="actions">
										{/* <Link to="/games/create">
											<button className="btn btn-orange">Add New Game</button>
										</Link> */}
									</div>
								</div>

                                <div className="row">
                                            <div className="col-sm-12 col-md-4">
                                                <div className="box">
                                                   
                                                            <React.Fragment>
                                                                <img src={efficialtecLogo} alt="logo" className="logo-icon mb-5" />
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <p>Efficialtec</p>
                                                                    <div title={this.context.user.user.efficialtec_connected ? 'Disconnect' : 'Connect'}>
                                                                   
                                                                        <Switch 
                                                                            id="efficialTec"
                                                                            name="efficialTec"
                                                                            onChange={this.handleChange}
                                                                            // disabled={true}
                                                                            checked={this.context.user.user.efficialtec_connected ? true : false}
                                                                            onColor="#f15a24"
                                                                        /> 
                                                                    </div>
                                                                </div>
                                                            </React.Fragment>
                                                </div>
                                            </div>
                                </div>
                            </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        );
    }
    
}

export default IntegrationsPage;