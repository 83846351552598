import React, { Component } from 'react';
import LeftSidebar from '../../components/shared/left-sidebar';
import Header from '../../components/shared/header';
import { Link } from 'react-router-dom';
import Loader from '../../components/loader/loader';
import ReactTable from 'react-table';
import Pagination from '../../components/pagination/pagination';
import { request } from '../../constants/constants';
import UserContext from '../../contexts/user';

class Leagues extends Component {
	
	static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            selected: {},
			pageSize: 10,
			currentPage: 1,
			selectedAll: {},
			indeterminateSign: false,
			leagues: [],
			isLoading: false
        }
    }

	handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				if (this.state.selectedAll[this.state.currentPage] === true) {
					this.setState({
						indeterminateSign: true
					})
				}
			});
		} else {
			this.setState({ pageSize: data }, () => {
				const start = (this.state.currentPage - 1) * this.state.pageSize;
				const end = start + this.state.pageSize;
				let newSelected = {};
				let newSelectPage;
				if (this.state.selectedAll[this.state.currentPage] === true) {
					newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
					this.state.leagues.forEach(data => {
						newSelected[data.id] = false;
					});
					this.state.leagues.slice(start, end).forEach(data => {
						newSelected[data.id] = true;
					});
					let newObj = Object.assign(this.state.selected, newSelected);
					const selectedList = Object.keys(newObj).filter(obj => {
						return newObj[obj] === true;
					});
					// this.props.selectedList(selectedList);
					this.setState({
						selected: newObj,
						selectedAll: newSelectPage,
						indeterminateSign: false
					});
				}
			});
		}
	}

	componentDidMount() {
		this.setState({isLoading: true})
		this.getAllLeagues().then(res => {
			console.log(res);
			this.setState({
				leagues: res.data,
				isLoading: false
			})
		}).catch(err => {
			console.error(err.response.message);
			this.setState({isLoading: false})
		})
	}

	getAllLeagues() {
		return request(
			'GET',
			`/auth/organisation/${this.context.user.current_org}/leagues`,
			{},
			true
		)
	}
    
    render() {
        const data = this.state.leagues;
		const columns = [{
            Header: 'Name',
			accessor: 'league_name' // String-based value accessors!
			// Cell: row => (
			// 	<span className="highlight">{row.original.league_name}</span>
			// )
		  }
		//   , {
        //     Header: 'Game Length',
        //     accessor: 'game_length',
        //   }, {
        //     Header: 'Actions',
        //     Cell: row => (
        //         <div className="actions">
        //             <ul className="list-inline">
        //                 <li>
        //                     <button className="link"><Link to={`/organisation/leagues/edit/${row.original.id}`}>Edit</Link></button>
        //                 </li>
        //                 <li><span className="seprator">|</span></li>
        //                 <li>
        //                     <button className="link" onClick={() => this.handleDelete(row.original.id)}>Delete</button>
        //                 </li>
        //             </ul>
        //         </div>
        //     )
		//  }
		];
        
        return (
            // <div>
			// 	<LeftSidebar />
			// 	<div className="main-panel">
			// 		<Header />
			// 		<div className="main-content">
			// 			<div className="container-fluid h-100">
							<div className="leagues">
								<div className="page-head mb_30">
									<div className="head">
										<h1 className="page-title">Leagues</h1>
										<nav>
											<ol className="breadcrumb page-title">
												<li className="breadcrumb-item"><Link to="/">Home</Link></li>
												<li className="breadcrumb-item current">Leagues</li>
											</ol>
										</nav>
									</div>
								</div>		

								<div className="table-area">
									{this.state.isLoading && <Loader />}
									<ReactTable
										PaginationComponent={Pagination}
										data={data}
										minRows={5}
										columns={columns}
										noDataText={this.state.isLoading ? '' : "No Leagues Found."}
										defaultPageSize={this.state.pageSize}
										pageSizeOptions={[10, 25, 50, 100]}
										resizable={true}
										onPageChange={(index) => this.handlePageChange(index)}
										onPageSizeChange={(size) => this.handlePageSizeChange(size)}
										getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
									/>
								</div>
							</div>
			// 			</div>
			// 		</div>
			// 	</div>
			// </div>
        );
    }
}

export default Leagues;