import React, { Component } from 'react';
import '../../public/styles/authentication.scss';
import './register.scss';
import PropTypes from 'prop-types';
import qs from 'query-string';
import Cookies from 'js-cookie';
import LogoBackground from '../../components/logo/LogoBackground';
import Logo from '../../components/logo/Logo';
import RegisterForm from '../../components/register-form/register-form';
import Axios from 'axios';
import { API_URL, request } from '../../constants/constants';
import Swal from 'sweetalert2';
import history from '../../history';

export default class Register extends Component {
	constructor(props) {
		super(props);
		this.state = {
			errors: {},
			isLoading: false,
			isVerified: false,
			alert_error_msg: '',
			alert_error_show: false
		}
	}
	
	componentWillMount() {
		// this.props.authCheck();
		// const referrer = qs.parse(this.props.location.search).referrer;
		// if (referrer != null) {
		// 	Cookies.set('efficialtec-referrer', referrer, { expires: 30, path: '/register'});
		// };
	}
	
	render() {
		return (
			<div className="authentication">
				<LogoBackground />
				<div className="register-container">
					<div className="register-wrap">
						<Logo />
						<RegisterForm alert_error_show={this.state.alert_error_show} alert_error_msg={this.state.alert_error_msg} checkRecaptcha={this.checkRecaptcha} loading={this.state.isLoading} errors={this.state.errors} onSignupSubmitted={this.onSignupSubmitted} />
					</div>
				</div>
			</div>
		);
	}
	
	onSignupSubmitted = (data) => {
		console.log(data);
		this.setState({ isLoading: true })
		const validationResult = this.doValidation(data);
		request('POST', '/auth/signup', data).then(res => {
			console.log(res);
			this.setState({
				isLoading: false
		})
			Swal.fire(
				'Success',
				'Registered successfully!',
				'success'
			).then(res => history.push('/login'))
		}).catch(err => {
			console.error(err)
			this.setState({
				...validationResult,
				isLoading: false
			})
		})
	}

	doValidation(data) { 
		let errors = {};
		let alert_error_show = false;
		let alert_error_msg = '';
		if(!this.state.isVerified) {
			alert_error_show = true;
			alert_error_msg = 'Please verify that you are a human.'
		}
		if(!data.organisation_name) {
			errors.organisation_name = 'The organisation name field is required.'
		}
		if(!data.user_name) {
			errors.user_name = 'The user name field is required.'
		}
		if(!data.user_email) {
			errors.user_email = 'The email field is required.'
		}
		if(!data.sport) {
			errors.sport = 'The sport field is required.'
		}
		if(!data.password) {
			errors.password = 'The password field is required.'
		}
		if(data.password_confirmation !== data.password) {
			errors.password_confirmation = 'Password not match.'
		}
		if(!data.password_confirmation) {
			errors.password_confirmation = 'The password confirmation field is required.'
		}
		return {
			alert_error_show: alert_error_show,
			alert_error_msg: alert_error_msg,
			errors: errors
		};
	}

	checkRecaptcha = (isVerified) => {
		this.setState({
			isVerified: isVerified
		});
	}

	
}

// Register.propTypes = {
// 	authCheck: PropTypes.func.isRequired,
// }