import React, { Component } from 'react';
import Loader from '../../../../../../components/loader/loader';

class AssignReferee extends Component {
	constructor(props) {
		super(props);
		this.state = {
			referees:[],
			assign_referees: {},
			originalProps: {}
		};
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handlesubmit = this.handlesubmit.bind(this);
	}

	handleInputChange = (id, index) => event => {
		let editedData = JSON.parse(JSON.stringify(this.state.referees));
		editedData[index][id] = Number(event.target.value);
		this.setState({
			referees: editedData
		});
	}

	async handlesubmit(event) {
		event.preventDefault();
		const {referees} = this.state;
		const data = {
			match_officials: referees
		}

		this.props.onAssignOfficial(data, this.props.game_id);
	}

	componentWillReceiveProps(nextProps) {
		if (Object.keys(this.state.originalProps).length === 0) {
			this.setState({
				originalProps: nextProps.assign_referees
			});
		}

		if (this.state.originalProps !== nextProps.assign_referees) {
			let newData = Object.keys(nextProps.assign_referees).map((key) => {
				let res;
				if (typeof nextProps.assign_referees[key] === 'string') {
					res = '';
				} else {
					res = nextProps.assign_referees[key].official.id
				}
				return {[key]: res };
			});

			this.setState({
				referees: newData,
				assign_referees: nextProps.assign_referees
			});
		}
	}

	render() {
		let referees = this.props.referees;
		return (
			<div className="assign-edit">
				<div className="wrap mb_15" style={{position: 'relative'}}>
					<div className="row">
					{this.props.loading && <Loader />}
						{this.props.fields.map((field, index) => (
							<div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 mb-1" key={index}>
								<div className="list-item">
									<div className="d-flex align-items-center justify-content-between">
										<div className="post">{field.field_name}</div>
										<div className="name">
											<select className="normal-select" onChange={this.handleInputChange(field.id, index)} value={this.state.referees.length > 0 ? this.state.referees[index][field.id] : ''}>
												<option value="">Select One</option>
												{Object.keys(referees).length !== 0 && Object.keys(referees[field.field_name]).map(obj => {
													return (
														<optgroup key={obj} label={obj}>
															{referees[field.field_name][obj].map(referee => <option key={referee.id} value={referee.id}>{referee.name}</option>)}
														</optgroup>);
												})}
											</select>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						{!this.props.loading && <button className="btn btn-orange" onClick={this.handlesubmit}>Submit</button>}
					</div>
				</div>
			</div>
		);
	}
}

export default AssignReferee;