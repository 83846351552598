import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Axios from 'axios';
import Swal from 'sweetalert2';
import Cookies from 'js-cookie';

Axios.interceptors.response.use( (response) => {
    return response;
},  (error) => {
    if (401 === error.response.status) {
      Swal.fire(
          'Session Expired',
          'Your session has expired please login again',
          'error'
      ).then( () => {
        Cookies.remove('user');
		window.location.href = '/login';
      });
      setTimeout(() => {
        Cookies.remove('user');
		window.location.href = '/login';
      }, 2000);
    }  else {
        return Promise.reject(error);
    }
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
