import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import FormError from '../form-error/form-error';
import Loader from '../loader/loader';
import AlertError from '../alert/error';
import AlertSuccess from '../alert/success';
import Cookies from 'js-cookie';
var Recaptcha = require('react-recaptcha');

export default function RegisterForm({ errors, loading, alert_error_msg, alert_error_show, alert_success_msg, alert_success_show, onSignupSubmitted, checkRecaptcha, verified }) {
	let organisation_name_error = null;
	let user_name_error = null;
	let user_email_error = null;
	let password_error = null;
	let password_confirmation_error = null;
	let sport_error = null;
	let register_loader = null;
	let alert_error = null;
	let alert_success = null;
	if (errors !== undefined) {
		for (var key in errors) {
			if (key === 'organisation_name')
				organisation_name_error = <FormError error={errors[key]} />;
			else if (key === 'user_name')
				user_name_error = <FormError error={errors[key]} />;
			else if (key === 'user_email')
				user_email_error = <FormError error={errors[key]} />;
			else if (key === 'password')
				password_error = <FormError error={errors[key]} />;
			else if (key === 'password_confirmation')
				password_confirmation_error = <FormError error={errors[key]} />;
			else
				sport_error = <FormError error={errors[key]} />;
		}
	}

	let organisation_name_field = null;
	let user_name_field = null;
	let user_email_field = null;
	let password_field = null;
	let password_confirmation_field = null;
	let sport_field = null;

	const signupSubmitted = (e) => {
		e.preventDefault();
		let data = {
			organisation_name: organisation_name_field.value,
			user_name: user_name_field.value,
			user_email: user_email_field.value,
			sport: sport_field.value,
			password: password_field.value,
			password_confirmation: password_confirmation_field.value,
		}

		const referrer = Cookies.get('efficialtec-referrer');
		if (referrer != null && referrer !== '') {
			data['referrer'] = referrer;
		}

		onSignupSubmitted(data, verified);
	}

	if (loading === true) {
		register_loader = <Loader />;
	}

	if (alert_error_show === true) {
		alert_error = <AlertError msg={alert_error_msg} />
	}

	if (alert_success_show === true) {
		alert_success = <AlertSuccess msg={alert_success_msg} />;
		document.getElementById('registerForm').reset();
		window.grecaptcha.reset();

	}

	var callback = () => {
		// console.log('Recaptcha loaded!!');
	}

	var verifyCallback = function (response) {
		if (response) {
			checkRecaptcha(true);
		}
		else {
			checkRecaptcha(false);
		}
	};
	return (
		<form onSubmit={signupSubmitted} id="registerForm">
			{register_loader}
			{alert_error}
			{alert_success}
			<label className="label">Sign Up</label>
			<div className="row">
				<div className="col-sm-12 col-md-12 col-lg-6">
					<div className="form-group">
						<input type="text" ref={(e) => organisation_name_field = e} placeholder="Organisation Name" className="form-control" />
						{organisation_name_error}
					</div>
				</div>
				<div className="col-sm-12 col-md-12 col-lg-6">
					<div className="form-group">
						<input type="text" ref={(e) => user_name_field = e} placeholder="Username" className="form-control" />
						{user_name_error}
					</div>
				</div>
				<div className="col-sm-12 col-md-12 col-lg-6">
					<div className="form-group">
						<input type="text" ref={(e) => user_email_field = e} placeholder="Email" className="form-control" />
						{user_email_error}
					</div>
				</div>
				<div className="col-sm-12 col-md-12 col-lg-6">
					<div className="form-group">
						<select className="form-control" ref={(e) => sport_field = e}>
							<option value="">Sports</option>
							<option value="Basketball">Basketball</option>
							<option value="Netball">Netball</option>
							<option value="Football">Football</option>
							<option value="Rugby Union">Rugby Union</option>
							<option value="Rugby League">Rugby League</option>
							<option value="Cricket">Cricket</option>
							<option value="AFL">AFL</option>
							<option value="Other">Other</option>
						</select>
						{sport_error}
					</div>
				</div>
				<div className="col-sm-12 col-md-12 col-lg-6">
					<div className="form-group">
						<input type="password" ref={(e) => password_field = e} placeholder="Password" className="form-control" />
						{password_error}
					</div>
				</div>
				<div className="col-sm-12 col-md-12 col-lg-6">
					<div className="form-group">
						<input type="password" ref={(e) => password_confirmation_field = e} placeholder="Confirm Password" className="form-control" />
						{password_confirmation_error}
					</div>
				</div>
			</div>
			<div className="captcha">
				<Recaptcha
					sitekey="6LcjxNgUAAAAAF4-zQIbVgxkKlVxzblb1SDyCAET"
					render="explicit"
					verifyCallback={verifyCallback}
					onloadCallback={callback}
				/>
			</div>
			<div className="action">
				<input type="submit" name="signin" value="Create a Account" className="submit" />
				<div className="signin">
					Already have an account?<Link to="/login">Log in Here</Link>
				</div>
			</div>
		</form>
	);
}

// RegisterForm.propTypes = {
// 	onSignupSubmitted: PropTypes.func.isRequired,
// 	checkRecaptcha: PropTypes.func.isRequired,
// 	errors: PropTypes.object,
// 	loading: PropTypes.bool,
// 	alert_error_show: PropTypes.bool,
// 	alert_error_msg: PropTypes.string,
// 	alert_success_show: PropTypes.bool,
// 	alert_success_msg: PropTypes.string,
// 	veified: PropTypes.bool
// }