import React, { Component } from 'react';
import LeftSidebar from '../../components/shared/left-sidebar';
import Header from '../../components/shared/header';
import './dashboard.scss';
// import userPic from '../../../../public/images/user.jpg';
// import { Scrollbars } from 'react-custom-scrollbars';
// import {Doughnut} from 'react-chartjs-2';
import 'fullcalendar-reactwrapper/dist/css/fullcalendar.min.css';
import FullCalendar from 'fullcalendar-reactwrapper';
import PropTypes from 'prop-types';
import moment from 'moment';
// import Chart from './chart/chart.container';
import $ from 'jquery';
import Loader from '../../components/loader/loader';
import { request } from '../../constants/constants';
import UserContext from '../../contexts/user';
import { Link } from 'react-router-dom';

const now = moment();

class Dashboard extends Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            daterange: 1,
            chart_lables: [],
            chart_data: [],
            events: [],
            games: [],
            origGames: []
        }
        // this.handleDateRange = this.handleDateRange.bi`nd(this);
    }

    componentDidMount() {
        this.setState({
            isLoading: true
        })
        const dateNow = new Date();
        const daterange = dateNow.setDate(dateNow.getDate() + this.state.daterange);
        this.getUpcomingGames().then(res => {
            console.log(res);
            this.setState({
                isLoading: false,
                games: res.data.data.filter(element => {
                    const gameDate =  new Date(`${element.game_date} ${element.game_time}`).getTime();
                    return gameDate <= daterange && gameDate >= new Date().getTime();
                }),
                origGames: [...res.data.data]
            })
        })
    }

    componentWillReceiveProps(nextProps) {
        console.log('nextProps',nextProps)
    }

    handleDateRange = (event) => {
        const dateNow = new Date();
        const daterange = dateNow.setDate(dateNow.getDate() + parseInt(event.target.value));
        this.setState({
            daterange: event.target.value,
            games: this.state.origGames.filter(element => {
                const gameDate =  new Date(`${element.game_date} ${element.game_time}`).getTime();
                return gameDate <= daterange && gameDate >= new Date().getTime();
            })
        })
    }

    getUpcomingGames() {
        return request('GET',`/auth/organisation/${this.context.user.current_org}/games/upcoming`, {}, true);
    }

    render() {
        return (
            // <div>
            //     <LeftSidebar />
            //     <div className="main-panel">
            //         <Header />
            //         <div className="main-content">
            //             <div className="container-fluid h-100">
                            <div className="dashboard">
                                <div className="page-head mb_30">
                                    <div className="head">
                                        <h1 className="page-title">Dashboard</h1>
                                    </div>
                                </div>
                                <div className="row mb_30">
                                    <div className="col-xl-6 col-lg-6 col-12 mb-sm-30 mb-md-30">
                                        <div className="card card-border card-upcoming-games">
                                            <div className="card-header d-flex align-items-center justify-content-between">
                                                <h2>Upcoming Games</h2>
                                                <div className="action">
                                                    <select className="normal-select" name="daterange" value={this.state.daterange} onChange={this.handleDateRange}>
                                                        <option value={1}>1 Day</option>
                                                        <option value={3}>3 Days</option>
                                                        <option value={7}>1 Week</option>
                                                        <option value={14}>2 Weeks</option>
                                                        <option value={21}>3 Weeks</option>
                                                        <option value={28}>4 Weeks</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                            {this.state.isLoading && <Loader />}

                                            <div className="home-activity mCustomscrollbar ">
                                                        <ul className="list">
                                                            { 
                                                                this.state.games.map( (game, i) => (
                                                                    <Link key={i} to={`/game/${game.id}/detail`}>
                                                                        <li  className=" d-flex justify-content-between">
                                                                            <div className="discription">
                                                                                <p className="name">{game.home_team && game.home_team.team_name} vs {game.away_team && game.away_team.team_name}s</p>
                                                                                <p>{game.league.league_name}</p>
                                                                            </div>
                                                                            <div className="time">
                                                                            <span>{game.game_date}, {game.game_time}</span>
                                                                            </div>
                                                                        </li>
                                                                    </Link>
                                                                )) 
                                                            }

                                                            {
                                                                (this.state.games.length == 0 && !this.state.isLoading) && (
                                                                    <center>
                                                                        No Data Found
                                                                    </center>
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-12">
                                        <div className="card card-border card-activity">
                                            <div className="card-header d-flex align-items-center justify-content-between">
                                                <h2>Activity</h2>
                                            </div>
                                            <div className="card-body">
                                                {/* {this.state.isLoading && <Loader />} */}

                                                {/* <center><p>No Data Found</p></center> */}
                                                    <div className="home-activity mCustomscrollbar ">

                                                        <center>
                                                            No Data Found
                                                        </center>
                                                             
                                                        {/* <ul className="list">
                                                            <li className=" d-flex justify-content-between">
                                                                <div className="discription">
                                                                    <span className="name">Jhon uploaded a game</span>
                                                                    <p>Championship League</p>
                                                                    <p>Bankstown Bruins vs Canberra Gunners</p>
                                                                    
                                                                </div>
                                                                <div className="time">
                                                                    <span>10 min</span>
                                                                </div>
                                                            </li>
                                                            <li className=" d-flex justify-content-between">
                                                                <div className="discription">
                                                                    <span className="name">Lorem uploaded a game</span>
                                                                    <p>Huddo's League</p>
                                                                    <p>Central Coast Waves vs Newcastle Hunters</p>
                                                                </div>
                                                                <div className="time">
                                                                    <span>10 min</span>
                                                                </div>
                                                            </li>
                                                        </ul> */}
                                                    </div>
                                {/* <div className="home-activity-action">
                                    <button className="link">See More Messages</button>
                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="fullcalendar">
                                                    <FullCalendar
                                                        id="upcoming-games"
                                                        themeSystem={'bootstrap4'}
                                                        header={{
                                                            left: 'prev,next today myCustomButton',
                                                            center: 'title',
                                                            right: 'month,basicWeek,basicDay'
                                                        }}
                                                        defaultDate={now}
                                                        navLinks={true} // can click day/week names to navigate views
                                                        editable={false}
                                                        eventLimit={true} // allow "more" link when too many events
                                                        height={'parent'}
                                                        events={this.state.events}
                                                        firstDay={1}
                                                        eventRender={function (event, element) {
                                                            $(element).popover({
                                                                html: true,
                                                                trigger: 'hover',
                                                                container: 'body',
                                                                placement: 'top',
                                                                content: '<b>League :</b> ' + event.League + '<br/><b>Venue :</b> ' + event.Venue + '<br/><b>Gender :</b> ' + event.Gender,
                                                                title: event.title
                                                            });
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
            //             </div>
            //         </div>
            //     </div>
            // </div>
        );
    }
}

export default Dashboard;