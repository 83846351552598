import React, { Component } from 'react';
import ForgotPassForm from './forgot-form';
import LogoBackground from '../../components/logo/LogoBackground';
import Logo from '../../components/logo/Logo';
import { request } from '../../constants/constants';
import Swal from 'sweetalert2';

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            errors: {}
        }
    }
    
    doValidation(data) {
        let errors = {};
        if(!data.email) {
            errors.email = 'The email field is required.';
        }
        return { errors };
    }

    onSubmit = (data) => {
        console.log(data)
        this.setState({ isLoading: true });
        const validationResult = this.doValidation(data);
        request('POST', '/auth/forgot', data).then(result => {
            console.log(result);
            this.setState({ 
                errors:  validationResult.errors,
                isLoading: false
            });
            Swal.fire(
                'Success',
                result.data.message,
                'success'
            );

        }).catch(err => {
            console.log(err.response);
            const alert_error_msg =  err.response.status == 401 ? 'Invalid email or password' : err.response.data.message
            this.setState({ 
                errors:  validationResult.errors,
                isLoading: false,
                alert_error_msg: alert_error_msg,
                alert_error_show: true
            });
        })
    }

    render() {
        return (
            <div className="authentication">
                <LogoBackground />
                <div className="login-container">
                    <div className="login-wrap">
                        <Logo />
                        <ForgotPassForm alert_error_show={this.state.alert_error_show} alert_error_msg={this.state.alert_error_msg} errors={this.state.errors} loading={this.state.isLoading} onSubmit={this.onSubmit}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ForgotPassword;