import React, { Component } from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import './confirm.scss';
import LogoBackground from '../../components/logo/LogoBackground';
import Loader from '../../components/loader/loader';
import Logo from '../../components/logo/Logo';
import Form from './form';

export default class Confirm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false
		}
	}

	componentWillMount() {
		// this.props.authCheck();
	}

	setLoader = (val) => {
		this.setState({
			loading: val
		})
	}


	render() {
		return (
			<div className="authentication">
				<LogoBackground />
				<div className="confirm-container">
					<div className="confirm-wrap">
						{this.state.loading && <Loader />}
						<Logo />
						<StripeProvider apiKey="pk_test_WVCt47HHMfHAiF7dAJ8mJ5Vq">
							<Elements>
								<Form 
									location={this.props.location}
									setLoader={this.setLoader}
								/>
							</Elements>
						</StripeProvider>
					</div>
				</div>
			</div>
		);
	}
}