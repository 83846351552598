import React, { Component } from 'react';
import emailSvg from '../../public/svg/email.svg';
import passwordSvg from '../../public/svg/password.svg';
import { Link } from 'react-router-dom';
import Loader from '../../components/loader/loader';
import AlertError from '../../components/alert/error';
import FormError from '../../components/form-error/form-error';

function ResetPasswordForm({props, loading, onSubmit, errors, alert_error_msg, alert_error_show, route_error }) {
        let email_field = null;
        let password_field = null;
        let password_confirm_field = null;
        const handleSubmit = (e) => {
			e.preventDefault();
			console.log(e.target.checkValidity());
            const params = new URLSearchParams(props.location.search);
            const token = params.get('token');
            let data = {
                email: email_field.value,
                password: password_field.value,
                password_confirmation: password_confirm_field.value,
                token: token
            }
            onSubmit(data);
        }

        let login_loader = null;
        if (loading === true) {
            login_loader = <Loader />;
        }

        let alert_error = null;
        if (alert_error_show === true) {
            alert_error = <AlertError msg={alert_error_msg} />
        }

        let email_error = null;
        let password_error = null;
        if (errors !== undefined) {
            for (var key in errors) {
                if (key === 'email')
                    email_error = <FormError error={errors[key]} />;
                else
                    password_error = <FormError error={errors[key]} />;
            }
        }

    
        return (
            <form  onSubmit={handleSubmit} method="POST" id="">
			{login_loader}
			{alert_error}
			<div className="signin">
				<label className="label">Email</label>
				<div className="form-group">
					<div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text">
								<span className="icon icon-email"><img src={emailSvg} className="imgsvg" alt="email" /></span>
							</span>
						</div>
						<input type="email" ref={(e) => email_field = e} className="form-control" placeholder="Email" />
					</div>
					{email_error}
				</div>
				<div className="form-group">
					<div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text">
								<span className="icon icon-pass">
									<img src={passwordSvg} className="imgsvg" alt="password" />
								</span>
							</span>
						</div>
						<input type="password" ref={(e) => password_field = e} className="form-control" placeholder="New Password" />
					</div>
					{password_error}
				</div>
                <div className="form-group">
					<div className="input-group">
						<div className="input-group-prepend">
							<span className="input-group-text">
								<span className="icon icon-pass">
									<img src={passwordSvg} className="imgsvg" alt="password" />
								</span>
							</span>
						</div>
						<input type="password" ref={(e) => password_confirm_field = e} className="form-control" placeholder="Confirm Password" />
					</div>
					{password_error}
				</div>
			</div>
			{/* <div className="forgot-keepme">
				<div className="row no-gutters">
					<div className="col-5">
						<div className="forgot-link">
							<Link to="/forgot-password">Forgot Password</Link>
						</div>
					</div>
					<div className="col-7">
						<div className="keep-me">
							<label htmlFor="signedin" className="check-box">
								<input type="checkbox" name="signedin" id="signedin" />
								<span className="text"><span>Keep Me Signed in</span></span>
							</label>
						</div>
					</div>
				</div>
			</div> */}
			<div className="action">
				<input type="submit" name="signin" value="Sign In" className="submit" />
			</div>
		</form>
        );

}

export default ResetPasswordForm;