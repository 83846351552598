import React, { Component } from 'react';
import { CardElement, injectStripe } from 'react-stripe-elements';
import Swal from 'sweetalert2';
import 'sweetalert2/src/sweetalert2.scss';
import axios from 'axios';
import qs from 'query-string';
import { API_URL, request } from '../../constants/constants';
import history from '../../history';


class CheckoutForm extends Component {
	constructor(props) {
		super(props);
		this.state = {
			name: '',
			games: '',
			coupon: ''
		}
		// this.submit = this.submit.bind(this);
		// this.handleChange = this.handleChange.bind(this);
		// this.handleNumber = this.handleNumber.bind(this);
	}

submit = async () => {
		this.props.setLoader(true);
		console.log(this.props)
		const token = qs.parse(this.props.location.search).token;
		const results = await this.props.stripe.createToken({ type: 'card', name: this.state.name });
		if (results.token) {
			let data = {
				token: token,
				quantity: this.state.games,
				// stripeToken: results.token.id,
				coupon: this.state.coupon
			};
			request('POST', '/auth/confirmorganisation', data).then(res => {
				this.props.setLoader(false);
				Swal.fire({
					title: "Success!",
					text: "Confirmation Success",
					type: "success",
				}).then(() => {
					history.push('/login')
				});
				setTimeout(() => {
					history.push('/login')
				}, 5000);
			}).catch(err => {
				this.props.setLoader(false);
				Swal.fire(
					'Error',
					err.response.data.message,
					'error'
				);
			});
		} else if (results.error) {
			this.props.setLoader(false);
			Swal.fire(
				'Error',
				results.error.message,
				'error'
			)
		}
	}

	handleChange = (event) => {
		this.setState({
			[event.target.name]: event.target.value
		})
	}

	handleNumber = () => {
		if (this.state.users === '') return;
		this.setState({
			users: Math.ceil(this.state.users / 10) * 10
		})
	}

	render() {
		const cardStyle = {
			base: {
				border: '1px solid black'
			}
		};
		return (
			<div className="checkout">
				<label className="label">Confirm Payment Details</label>
				<div className="form-group">
					<input type="text" placeholder="Name" name="name" onChange={this.handleChange} className="form-control" />
				</div>
				<div className="form-group">
					<input type="number" placeholder="Number Of Games" value={this.state.games} name="games" onChange={this.handleChange} className="form-control" />
					{/* <label style={{color: 'red', fontSize: '12px', fontStyle: 'italic'}}>Note: users are billed in blocks of 10 users</label> */}
				</div>
				<div className="form-group">
					<input type="text" placeholder="Coupon Code (Optional)" name="coupon" onChange={this.handleChange} className="form-control" />
				</div>
				<div className="form-group no-animation">
					<CardElement hidePostalCode={true} style={cardStyle} />
				</div>
				<button className="btn btn-orange btn-full" onClick={this.submit}>Submit</button>
			</div>
		);
	}
}

export default injectStripe(CheckoutForm);