import React, { Fragment } from 'react';
import './game-list.scss';
import { Link } from 'react-router-dom';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import TimePicker from 'rc-time-picker';
import 'rc-time-picker/assets/index.css';
import dateIcon from '../../../public/svg/date.svg';
import moment from 'moment';
import GameResults from './game_results/game_results';
import classNames from 'classnames';
import Swal from 'sweetalert2';
import LeftSidebar from '../../../components/shared/left-sidebar';
import Header from '../../../components/shared/header';
import UserContext from '../../../contexts/user';
import { request, getToken, API_URL } from '../../../constants/constants';
import history from '../../../history';
import Loader from '../../../components/loader/loader';
import Modal from 'react-responsive-modal';
import Axios from 'axios';
import { DropzoneComponent } from 'react-dropzone-component';
import '../../../public/styles/dropzone.css';
import '../../../../node_modules/dropzone/dist/min/dropzone.min.css';
import * as AWS from 'aws-sdk';

const format = 'YYYY-MM-DD';
const now = moment();

class GameListPage extends React.Component {

	s3Client;
	static contextType = UserContext;

	constructor(props) {
		super(props);
		AWS.config.region = 'ap-southeast-2'; // Region
		AWS.config.update({
			accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
			secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
            region: process.env.REACT_APP_AWS_DEFAULT_REGION,
            maxRetries: 2,
            httpOptions: {
                timeout:  1800000000,
                // connectTimeout: 5000
            }
		});
		this.s3Client = new AWS.S3();
		this.state = {
			team_data: [],
			gender_data: [],
			venue_data: [],
			league_data: [],
			pattern_data: [],
			showDateInput: false,
			showAssign: false,
			filters: {
				venue: 0,
				league: 0,
				gender: 0,
				date_range: 0,
				game_date: undefined,
				game_time: 0,
				home_team: 0,
				away_team: 0,
				pattern: 0,
				official_status: 0,
				chooseDate: 'date-range'
			},
			games: [],
			isFilterActive: false,
			isSortByActive: false,
			sortByFilters: [],
			modal: false
		};
		this.toggleBtn = React.createRef();
		this.toggleBtnSortBy = React.createRef();
		this.toggleAssign = this.toggleAssign.bind(this);
		this.onTimeChange = this.onTimeChange.bind(this);
		this.selectedGames = this.selectedGames.bind(this);
		this.handlePublish = this.handlePublish.bind(this);
	}

	componentDidMount() {
		this.init();
		// this.props.fetchGamesdata();
		// const rememberedFilters = JSON.parse(localStorage.getItem('filters'));
		// const rememberedSortBy = JSON.parse(localStorage.getItem('sortby'));

		// let fetch = [];

		// if (rememberedFilters != null) {
		// 	fetch.push(this.generateParams(rememberedFilters));
		// 	this.setState({
		// 		isFilterActive: true,
		// 		filters: rememberedFilters
		// 	});
		// } else {
		// 	fetch.push(new URLSearchParams());
		// 	this.setState({
		// 		isFilterActive: false
		// 	});
		// }

		// if (rememberedSortBy != null) {
		// 	fetch.push(this.generateParamsSortBy(rememberedSortBy));
		// 	this.setState({
		// 		isSortByActive: true,
		// 		sortByFilters: rememberedSortBy
		// 	});
		// } else {
		// 	fetch.push(new URLSearchParams());
		// 	this.setState({
		// 		isSortByActive: false
		// 	});
		// }

		// this.props.fetchGames(...fetch);
	}

	init = e => {
		console.log('getAllGames')
		this.setState({ isLoading: true })
		this.getAllGames().then(results => {
			console.log(results);
			this.setState({
				games: results.data.data,
				isLoading: false
			})
		}).catch(err => {
			console.error(err.response.message);
			this.setState({ isLoading: false })
		})
	}

	getAllGames() {
		return request('GET', `/auth/organisation/${this.context.user.current_org}/games`, null, true)
	}

	componentDidUpdate(prevProps) {
		// if (prevProps.teams !== this.props.teams) {
		// 	this.setState({ team_data: this.props.teams });
		// }
		// if (prevProps.leagues !== this.props.leagues) {
		// 	this.setState({ league_data: this.props.leagues });
		// }
		// if (prevProps.venues !== this.props.venues) {
		// 	this.setState({ venue_data: this.props.venues });
		// }
		// if (prevProps.genders !== this.props.genders) {
		// 	this.setState({ gender_data: this.props.genders });
		// }
		// if (prevProps.patterns !== this.props.patterns) {
		// 	this.setState({ pattern_data: this.props.patterns });
		// }
	}

	selectedGames(data) {
		let games = data.map((data) => {
			return Number(data);
		});
		this.setState({ games: games });
	}

	handlePublish() {
		Swal({
			title: 'Are you sure?',
			text: "Do you want to publish selected games?",
			type: 'question',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, publish selected games!'
		}).then((result) => {
			if (result.value) {
				this.props.publishGames({ games: this.state.games });
			}
		})
	}

	toggleAssign() {
		this.setState({
			showAssign: !this.state.showAssign
		});
	}

	onTimeChange(value) {
		this.setState(state => {
			return {
				filters: {
					...state.filters,
					game_time: value.format("HH:mm")
				}
			}
		})
	}

	onChange = (value) => {
		this.setState(state => {
			return {
				filters: {
					...state.filters,
					game_date: value
				}
			}
		})
	}

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState({
			[name]: value
		});
	};

	handleFilterChange = event => {
		const target = event.target;
		const value = target.value;
		const name = target.name;

		this.setState(state => {
			return {
				filters: {
					...state.filters,
					[name]: value
				}
			}
		});
	}

	onResetFilter = () => {
		const { sortByFilters, isSortByActive } = this.state;
		this.setState({
			filters: {
				venue: 0,
				league: 0,
				gender: 0,
				date_range: 0,
				game_date: undefined,
				game_time: 0,
				home_team: 0,
				away_team: 0,
				pattern: 0,
				official_status: 0,
				chooseDate: 'date-range'
			},
			isFilterActive: false
		}, () => {
			localStorage.removeItem('filters');
		});
		this.toggleBtn.click();

		let fetch = [];
		fetch.push(new URLSearchParams());

		if (isSortByActive) {
			fetch.push(this.generateParamsSortBy(sortByFilters));
		} else {
			fetch.push(new URLSearchParams());
		}

		this.props.fetchGames(...fetch);
	}

	onApplyFilter = () => {
		const { filters, sortByFilters, isSortByActive } = this.state;
		localStorage.setItem('filters', JSON.stringify(filters));
		this.toggleBtn.click();
		this.setState({
			isFilterActive: true
		})

		let fetch = [];
		fetch.push(this.generateParams(filters));

		if (isSortByActive) {
			fetch.push(this.generateParamsSortBy(sortByFilters));
		}

		console.log(fetch)
		// this.props.fetchGames(...fetch);
	};

	generateParams = filters => {
		let date;
		if (filters.chooseDate === 'date-range') {
			date = {
				daterange: filters.date_range === 0 ? null : filters.date_range,
				game_date: null
			}
		} else {
			date = {
				daterange: null,
				game_date: filters.game_date == null ? null : moment(filters.game_date).format(format),
			}
		}

		let obj = {
			venue_id: filters.venue === 0 ? null : filters.venue,
			league_id: filters.league === 0 ? null : filters.league,
			gender: filters.gender === 0 ? null : filters.gender,
			game_time: filters.game_time === 0 ? null : filters.game_time,
			home_team_id: filters.home_team === 0 ? null : filters.home_team,
			away_team_id: filters.away_team === 0 ? null : filters.away_team,
			pattern: filters.pattern === 0 ? null : filters.pattern,
			status: filters.official_status === 0 ? null : filters.official_status
		};

		let newObj = { ...obj, ...date };

		let params = new URLSearchParams();
		Object.keys(newObj).forEach(key => {
			if (newObj[key] != null) {
				params.append(key, newObj[key]);
			}
		});

		return params;
	};

	handleClearCalender = () => {
		this.setState(state => {
			return {
				filters: {
					...state.filters,
					game_date: undefined
				}
			}
		});
	}

	sortByOptions = () => {
		return (
			<Fragment>
				<option value="0">Unselect</option>
				<option value="1" disabled={this.state.sortByFilters.includes("1")}>Date, newest to oldest</option>
				<option value="2" disabled={this.state.sortByFilters.includes("2")}>Date oldest to newest</option>
				<option value="3" disabled={this.state.sortByFilters.includes("3")}>Time newest to oldest</option>
				<option value="4" disabled={this.state.sortByFilters.includes("4")}>Time oldest to newest</option>
				<option value="5" disabled={this.state.sortByFilters.includes("5")}>Venue A- Z</option>
				<option value="6" disabled={this.state.sortByFilters.includes("6")}>Venue Z - A</option>
				<option value="7" disabled={this.state.sortByFilters.includes("7")}>League A - Z</option>
				<option value="8" disabled={this.state.sortByFilters.includes("8")}>League Z - A</option>
			</Fragment>
		)
	};

	handleSortBy = (e, index) => {
		let newArr = [...this.state.sortByFilters];

		if (Number(e.target.value) === 0) {
			newArr.splice(index, 1);
		} else {
			newArr[index] = e.target.value;
		}

		this.setState({
			sortByFilters: newArr
		});
	}

	generateParamsSortBy = sortBy => {
		let params = new URLSearchParams();
		sortBy.forEach((value, key) => {
			params.append(`sortby[${key}]`, value);
		});
		return params;
	}

	onApplySortBy = () => {
		const { filters, sortByFilters, isFilterActive } = this.state;

		localStorage.setItem('sortby', JSON.stringify(sortByFilters));
		this.toggleBtnSortBy.click();
		this.setState({
			isSortByActive: true
		});

		let params = new URLSearchParams();
		this.state.sortByFilters.forEach(value => {
			params.append('sortby', value);
		});

		let fetch = [];
		if (isFilterActive) {
			fetch.push(this.generateParams(filters));
		} else {
			fetch.push(new URLSearchParams());
		}
		fetch.push(this.generateParamsSortBy(sortByFilters));
		// this.props.fetchGames(...fetch);
		console.log(fetch)
	};

	onResetSortBy = () => {
		const { filters, isFilterActive } = this.state;
		this.setState({
			sortByFilters: [],
			isSortByActive: false
		}, () => {
			localStorage.removeItem('sortby');
		});
		this.toggleBtnSortBy.click();

		let fetch = [];
		if (isFilterActive) {
			fetch.push(this.generateParams(filters));
		} else {
			fetch.push(new URLSearchParams());
		}

		fetch.push(new URLSearchParams());
		this.props.fetchGames(...fetch);
	};

	showModal = id => e => {
		this.setState({
			modal: true,
			currentGameId: id
		})
	}

	closeModal = () => {
		this.setState({
			modal: false
		})
	}
	setModalState = state => e => {
		console.log(state)
		this.setState({
			modal: state
		})
	}

	onFileChange = e => {
		this.setState({
			fileName: e.target.files[0].name,
			fileToUpload: e.target.files[0]
		})
	}

	handleSubmit = e => {
		this.setState({
			isLoading: true,
			modal: false
		})
		const file = this.state.fileToUpload;
		console.log(file);
		let formData = new FormData();    //formdata object

		formData.append('video', file);   //append the values with key, value pair

		const config = {     
			headers: { 'content-type': 'multipart/form-data', 'Authorization': `Bearer ${getToken()}` },
		}

		Axios.post(`${API_URL}/auth/organisation/${this.context.user.current_org}/games/${this.state.currentGameId}/upload`, formData, config)
		.then(response => {
			console.log(response);
			this.setState({
				isLoading: false
			})
			Swal.fire(
				'Success!',
				'Video uploaded successfully',
				'success'
			)
		})
		.catch(error => {
			console.log(error);
			this.setState({
				isLoading: false
			})
			console.log(error.response)
			Swal.fire(
				'Error!',
				error.response.data.message,
				'error'
			)
		});

	}
	
	getGameVideoPath(id) {
        return request(
            'GET',
            `/auth/organisation/${this.context.user.current_org}/games/${id}/video-path`,
            {},
            true
        )
    }

	handleDownload =  id =>  e  => {
		console.log('download', id);
		// window.open(`/game/${id}/download`, '_blank');
		this.getGameVideoPath(id).then(res => {
            console.log(res);
            const path =  res.data.path;
            var params = {
                Bucket: process.env.REACT_APP_AWS_BUCKET, 
                Key: path,
                Expires: 10800, 
                // Range: "bytes=0-10000000"
            };
            this.s3Client.getSignedUrl('getObject', params, function (err, url) {
                console.log('The URL is', url);
                const a = document.createElement('A');
                a.href = url;
                a.download = url.substr(url.lastIndexOf('/') + 1);
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              });
        })
	}
		
	render() {
		let dropZone ;
		const componentConfig = {
			showFiletypeIcon: true,
			postUrl: `${API_URL}/auth/organisation/${this.context.user.current_org}/games/${this.state.currentGameId}/upload`
		};
		const djsConfig = {
			addRemoveLinks: true,
			headers: {
				"Authorization": `Bearer ${getToken()}`
			},
			paramName: 'video',
			acceptedFiles: 'video/*',
			maxFilesize: 3000, 
			timeout: 9999999,
			dictDefaultMessage: '<p><span class="icon icon-upload"><svg style="font-size:3em" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="cloud-upload-alt" class="svg-inline--fa fa-cloud-upload-alt fa-w-20 fa fa-file-excel" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zM393.4 288H328v112c0 8.8-7.2 16-16 16h-48c-8.8 0-16-7.2-16-16V288h-65.4c-14.3 0-21.4-17.2-11.3-27.3l105.4-105.4c6.2-6.2 16.4-6.2 22.6 0l105.4 105.4c10.1 10.1 2.9 27.3-11.3 27.3z"></path></svg></span></p><p>Drop files here or click to upload.</p>'
			
		};

		const eventHandlers =  {
			success: (res) => {
				console.log(res);
				Swal.fire(
					'Success',
					'Video Upload successfully!',
					'success'
				).then(() => {
					this.closeModal();
				})
			},
			maxfilesreached: (res) => {
				Swal.fire(
					'Error',
					'Max File size reached!',
					'error'
				)
			},
			error: (err) => {
				console.error(err);
				console.log(dropZone);
				Swal.fire(
					'Error',
					'Upload error please try again!',
					'error'
				)
			}
		}

		const state = this.state;
		const calendar = (<Calendar
			style={{ zIndex: 1000 }}
			dateInputPlaceholder="Select Date"
			formatter={format}
			disabledTime={null}
			timePicker={null}
			defaultValue={now}
			showDateInput={state.showDateInput}
			showClear={true}
		/>);


		const optionGender = this.state.gender_data.map((gender, key) =>
			<option key={key + 1} value={key + 1}>{gender.Gender_Name}</option>
		);
		const optionTeam = this.state.team_data.map(team =>
			<option key={team.Team_ID} value={team.Team_ID}>{team.Team_Name}</option>
		);
		const optionVenue = this.state.venue_data.map(venue =>
			<option key={venue.Venue_ID} value={venue.Venue_ID}>{venue.Venue_Name}</option>
		);
		const optionPattern = this.state.pattern_data.map(pattern =>
			<option key={pattern.Pattern_ID} value={pattern.Pattern_ID}>{pattern.Pattern_Name}</option>
		);
		const optionLeague = this.state.league_data.map(league =>
			<option key={league.League_ID} value={league.League_ID}>{league.League_Name}</option>
		);

		const params = new URLSearchParams(this.props.location.search);
		const status = params.get('status');
		const daterange = params.get('daterange');
		return (

			// <div>
			// 	<LeftSidebar />
			// 	<div className="main-panel">
			// 		<Header />
			// 		<div className="main-content">
			// 			<div className="container-fluid h-100">




			<div className="game-list-page">

				<Modal
					open={this.state.modal}
					onClose={this.closeModal}
					showCloseIcon={false}
					center
					classNames={{
						modal: "modal fade show auto-width-modal"
					}}
				>
					<div className="modal-dialog modal-lg modal-dialog-centered">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Upload Game</h5>
								<button type="button" className="close" onClick={this.closeModal}>
									<span>&times;</span>
								</button>
							</div>
							<div className="modal-body">
								{this.props.modalLoader && <Loader />}
								<div className="row">
									<div className="col">
									<DropzoneComponent config={componentConfig}
									 	djsConfig={djsConfig}
                       					eventHandlers={eventHandlers}
									/>

										{/* <div className="form-group">
											<label className="label">Video</label>
											<div className="input-effect-1">
												<div className="input-group">
													<div className="custom-file">
														<input onChange={this.onFileChange} type="file" className="custom-file-input" id="inputGroupFile04" />
														<label className="custom-file-label file-select" htmlFor="inputGroupFile04">{ this.state.fileName ? this.state.fileName : 'Choose File' }</label>
													</div>
												</div>
												<div className="focus-border"></div>
											</div>
										</div> */}
									</div>
								</div>
							</div>

							{/* <div className="modal-footer text-center">
								<button className="btn btn-orange" onClick={this.handleSubmit}>Submit</button>
							</div> */}
						</div>
					</div>
				</Modal>

				<div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title" onClick={this.props.showLoader}>Games</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">All Games</li>
							</ol>
						</nav>
					</div>
					<div className="actions">
						{/* <Link to="/games/create">
											<button className="btn btn-orange">Add New Game</button>
										</Link> */}
					</div>
				</div>
				<div className="actions mb_15">
					<ul className="list-inline">
						{/* <li className="list-inline-item mb_15">
							<button className="btn btn-orange" onClick={this.handlePublish} disabled={this.state.games.length < 1} >Publish</button>
						</li> */}
						<li className="list-inline-item mb_15">
							<button
								ref={(node) => this.toggleBtnSortBy = node}
								className={classNames('btn', { 'btn-standard': !this.state.isSortByActive }, { 'btn-active': this.state.isSortByActive })}
								data-toggle="modal"
								data-target="#setup"
							>
								View Setup {this.state.isSortByActive && '(Active)'}
							</button>
						</li>
						<li className="list-inline-item mb_15">
							<button
								ref={(node) => this.toggleBtn = node}
								className={classNames('btn', { 'btn-standard': !this.state.isFilterActive }, { 'btn-active': this.state.isFilterActive })}
								data-toggle="modal"
								data-target="#filter"
							>
								Filter {this.state.isFilterActive && 'is Active'}
							</button>
						</li>
						{/* <li className="list-inline-item mb_15">
							<button className="btn btn-black" onClick={this.toggleAssign}>{this.state.showAssign ? 'Hide' : 'Show'} Bulk Allocate</button>
						</li> */}
					</ul>
					<div className="modal fade" id="filter">
						<div className="modal-dialog modal-dialog-centered modal-lg">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">Filter</h5>
									<button type="button" className="close" data-dismiss="modal">
										<span>&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<div className="row">
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Venue</label>
												<div className="input-effect-1">
													<select value={this.state.filters.venue} name="venue" onChange={event => this.handleFilterChange(event)} className="normal-select">
														<option value={0}>Select Venue</option>
														{optionVenue}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">League</label>
												<div className="input-effect-1">
													<select value={this.state.filters.league} name="league" onChange={event => this.handleFilterChange(event)} className="normal-select">
														<option value={0}>Select League</option>
														{optionLeague}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Gender</label>
												<div className="input-effect-1">
													<select value={this.state.filters.gender} name="gender" onChange={event => this.handleFilterChange(event)} className="normal-select">
														<option value={0}>Select Gender</option>
														{optionGender}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label d-flex align-items-center"><input className="mr-1" type="radio" name="chooseDate" value="date-range" checked={state.filters.chooseDate === 'date-range'} onChange={event => this.handleFilterChange(event)} /> Date Range</label>
												<div className="input-effect-1">
													<select className={classNames("normal-select", { "disabled-datepicker": (state.filters.chooseDate !== 'date-range') })} value={this.state.filters.date_range} disabled={(state.filters.chooseDate !== 'date-range')} name="date_range" onChange={event => this.handleFilterChange(event)}>
														<option value={0}>Select Range</option>
														<option value={1}>1 Day</option>
														<option value={3}>3 Days</option>
														<option value={7}>1 Week</option>
														<option value={14}>2 Weeks</option>
														<option value={21}>3 Weeks</option>
														<option value={28}>4 Weeks</option>
														<option value="future">All Future Games</option>
														<option value="past">All Past Games</option>
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label d-flex align-items-center justify-content-between">
													<div>
														<input className="mr-1" type="radio" name="chooseDate" value="game-dates" checked={state.filters.chooseDate === 'game-dates'} onChange={event => this.handleFilterChange(event)} />
														Game Dates
													</div>
													{state.filters.game_date != null && <button className="button-link" onClick={this.handleClearCalender}>Clear</button>}
												</label>
												<div className="input-effect-1">
													<div className={classNames("datepicker", { "disabled-datepicker": (state.filters.chooseDate !== 'game-dates') })} id="datepicker">
														<DatePicker
															animation="slide-up"
															disabled={(state.filters.chooseDate !== 'game-dates')}
															calendar={calendar}
															value={state.filters.game_date}
															onChange={this.onChange}
															getCalendarContainer={() => document.getElementById('datepicker')}
															className="custom-calendar"
															showClear={true}
														>
															{
																({ value }) => {
																	return (
																		<div className="input-group">
																			<input
																				placeholder="Select Date"
																				disabled={(state.filters.chooseDate !== 'game-dates')}
																				readOnly
																				tabIndex="-1"
																				className="ant-calendar-picker-input ant-input form-control"
																				value={value == null ? '' : moment(value).format(format)}
																			/>
																			<div className="input-group-append">
																				<span className="input-group-text">
																					<img src={dateIcon} alt="date" className="imgsvg" />
																				</span>
																			</div>
																		</div>
																	);
																}
															}
														</DatePicker>
													</div>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Time</label>
												<div className="timepicker">
													<div className="input-group">
														<TimePicker showSecond={false} value={moment(this.state.filters.game_time, 'HH:mm')} onChange={this.onTimeChange} />
														<div className="input-group-append">
															<span className="input-group-text">
																<img src={dateIcon} alt="date" className="imgsvg" />
															</span>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Home Team</label>
												<div className="input-effect-1">
													<select value={this.state.filters.home_team} name="home_team" onChange={event => this.handleFilterChange(event)} className="normal-select">
														<option value={0}>Select Home Team</option>
														{optionTeam}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Away Team</label>
												<div className="input-effect-1">
													<select value={this.state.filters.away_team} name="away_team" onChange={event => this.handleFilterChange(event)} className="normal-select">
														<option value={0}>Select Away Team</option>
														{optionTeam}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										{/* <div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Pattern</label>
												<div className="input-effect-1">
													<select value={this.state.filters.pattern} name="pattern" onChange={event => this.handleFilterChange(event)} className="normal-select">
														<option value={0}>Select Pattern</option>
														{optionPattern}
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div> */}
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Game Status</label>
												<div className="input-effect-1">
													<select value={this.state.filters.official_status} name="official_status" onChange={event => this.handleFilterChange(event)} className="normal-select">
														<option value={0}>Select Status</option>
														<option value="Pending">Awaiting Upload</option>
														<option value="Confirmed">Video Uploaded</option>
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="modal-footer text-center">
									<button type="button" className="btn btn-orange" onClick={this.onApplyFilter}>Apply</button>
									<button type="button" className="btn btn-orange" onClick={this.onResetFilter}>Reset Filter</button>
								</div>
							</div>
						</div>
					</div>
					<div className="modal fade" id="setup">
						<div className="modal-dialog modal-dialog-centered modal-lg">
							<div className="modal-content">
								<div className="modal-header">
									<h5 className="modal-title">View Setup</h5>
									<button type="button" className="close" data-dismiss="modal">
										<span>&times;</span>
									</button>
								</div>
								<div className="modal-body">
									<div className="row">
										<div className="col-xl-4 col-lg-4 col-md-4 col-12">
											<div className="form-group">
												<label className="label">Sort By</label>
												<div className="input-effect-1">
													<select
														className="normal-select"
														onChange={e => this.handleSortBy(e, 0)}
														value={
															this.state.sortByFilters[0] == null
																? 0
																: this.state.sortByFilters[0]
														}
													>
														<this.sortByOptions />
													</select>
													<div className="focus-border"></div>
												</div>
											</div>
										</div>
										{[...Array(5)].map((element, index) => (
											<div key={index} className="col-xl-4 col-lg-4 col-md-4 col-12">
												<div className="form-group">
													<label className="label">Then By</label>
													<div className="input-effect-1">
														<select
															className="normal-select"
															disabled={this.state.sortByFilters.length < (Number(index + 1))}
															onChange={e => this.handleSortBy(e, Number(index + 1))}
															value={
																this.state.sortByFilters[Number(index + 1)] == null
																	? 0
																	: this.state.sortByFilters[Number(index + 1)]
															}
														>
															<this.sortByOptions />
														</select>
														<div className="focus-border"></div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
								<div className="modal-footer text-center">
									<button type="button" className="btn btn-orange" onClick={this.onApplySortBy}>Apply</button>
									<button type="button" className="btn btn-orange" onClick={this.onResetSortBy}>Reset</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<GameResults
					showAssign={this.state.showAssign}
					official_status={status}
					daterange={daterange}
					selectedGames={this.selectedGames}
					games={this.state.games}
					loading={this.state.isLoading}
					modal={this.setModalState}
					download={this.handleDownload}
					showModal={this.showModal}
					init={this.init}										
				/>
			</div>
			// 			</div>
			// 		</div>
			// 	</div>
			// </div>
		);
	}
}

export default GameListPage;
