import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import Loader from '../../components/loader/loader';
import { request } from '../../constants/constants';
import Pagination from '../../components/pagination/pagination';
import UserContext from '../../contexts/user';

class PendingUsers extends Component {
	
	static contextType = UserContext;

	constructor(props) {
		super(props);
		this.state = {
			selected: {},
			pageSize: 10,
			currentPage: 1,
			selectedAll: {},
			indeterminateSign: false,
			isLoading: false,
			users: []
		}
	}

	componentDidMount() {
		this.setState({
			isLoading: true
		})
		this.getAllPendingUsers().then(res => {
			console.log(res);
			this.setState({
				isLoading: false,
				users: res.data
			})
		}).catch(err => {
			console.log(err.response);
			this.setState({
				isLoading: false
			})
		})
	}

	toggleSelectAll() {
		let newSelected = {};
		let newSelectPage;
		const start = (this.state.currentPage - 1) * this.state.pageSize;
		const end = start + this.state.pageSize;
		if (this.state.selectedAll[this.state.currentPage] === false || this.state.selectedAll[this.state.currentPage] == null || this.state.indeterminateSign === true) {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true});
			this.state.users.slice(start, end).forEach(data => {
				newSelected[data.id] = true;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage,
				indeterminateSign: false
			});
		} else {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage] : false });
			this.state.users.slice(start, end).forEach(data => {
				newSelected[data.id] = false;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage
			});
		}
	}

	toggleRow(gameId) {
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[gameId] = !this.state.selected[gameId];

		const selectedGames = Object.keys(newSelected).filter(obj => {
			return newSelected[obj] === true;
		});

		this.setState({
			selected: newSelected,
		});
	}

	handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				if (this.state.selectedAll[this.state.currentPage] === true) {
					this.setState({
						indeterminateSign: true
					})
				}
			});
		} else {
			this.setState({ pageSize: data }, () => {
				const start = (this.state.currentPage - 1) * this.state.pageSize;
				const end = start + this.state.pageSize;
				let newSelected = {};
				let newSelectPage;
				if (this.state.selectedAll[this.state.currentPage] === true) {
					newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
					this.state.users.forEach(data => {
						newSelected[data.id] = false;
					});
					this.state.users.slice(start, end).forEach(data => {
						newSelected[data.id] = true;
					});
					let newObj = Object.assign(this.state.selected, newSelected);
					const selectedList = Object.keys(newObj).filter(obj => {
						return newObj[obj] === true;
					});
					this.setState({
						selected: newObj,
						selectedAll: newSelectPage,
						indeterminateSign: false
					});
				}
			});
		}
	}

	handleSubmit = e => {
		
	}

	getAllPendingUsers() {
		return request('GET', `/auth/organisation/${this.context.user.current_org}/users/pending`, {}, true)
	}


    render() {
		const data = this.state.users;
		const columns = [
			{
				Header: (header) => (
					<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
						<input
							type="checkbox"
							className="custom-control-input"
							id="selectAllCheckBox"
							checked={this.state.selectedAll[this.state.currentPage] || false}
							onChange={() => this.toggleSelectAll()}
							ref={input => {
								if (input) {
									input.indeterminate = this.state.indeterminateSign;
								}
							}}
						/>
						<label className="custom-control-label" htmlFor="selectAllCheckBox"></label>
					</div>
				),
				width: 52,
				Cell: row => (
					<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
						<input type="checkbox" className="custom-control-input" id={`game-id-${row.original.id}`} checked={this.state.selected[row.original.id] || false} onChange={() => this.toggleRow(row.original.id)} />
						<label className="custom-control-label" htmlFor={`game-id-${row.original.id}`}></label>
					</div>
				),
				className: 'header-checkbox-toggle',
				headerStyle: { padding: '1.07143em 0' },
				style: { padding: '0.71429em 0' }
				
			},
			{
				Header: 'Name',
				accessor: 'name' 
			},
			{
				Header: 'Email',
				accessor: 'email' 
			},
			{
				Header: 'Actions',
				Cell: row => (
					<div className="actions">
						<ul className="list-inline">
							<li>
								<button className="link"><Link to={`/users/edit/${row.original.id}`}>Edit</Link></button>
							</li>
						</ul>
					</div>
				)
			 }
		];
        return (
            <div className="invite-users">
                <div className="page-head mb_30">
					<div className="head">
						<h1 className="page-title" >Pending Users</h1>
						<nav>
							<ol className="breadcrumb page-title">
								<li className="breadcrumb-item"><Link to="/">Home</Link></li>
								<li className="breadcrumb-item current">Pending Users</li>
							</ol>
						</nav>
					</div>
					<div className="actions">
					<Link to={`/users/invite`}><button className="btn btn-orange"><span className="text-white">Invite User</span></button></Link>
					</div>
				</div>
				<div className="table-area">
					{this.state.isLoading && <Loader />}
					<ReactTable
						PaginationComponent={Pagination}
						data={data}
						minRows={5}
						columns={columns}
						noDataText={this.state.isLoading ? '' : "No Users Found."}
						defaultPageSize={this.state.pageSize}
						pageSizeOptions={[10, 25, 50, 100]}
						resizable={true}
						onPageChange={(index) => this.handlePageChange(index)}
						onPageSizeChange={(size) => this.handlePageSizeChange(size)}
						getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
					/>
				</div>
            </div>
        );
    }
}

export default PendingUsers;