import React, { Component } from 'react';
import LoginBg from '../../public/images/login_bg.jpg';


class LogoBackground extends Component {
    render() {
        return (
            <div className="thumb">
                <img src={LoginBg} alt="login" className="imgbg" />
            </div>
        );
    }
}

export default LogoBackground;