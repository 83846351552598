import Axios from "axios";
import Cookies from 'js-cookie';

export const API_URL = "https://api.video.efficialtec.com/v1";
export const PROFILE_IMAGE_BASE_URL = "https://api.video.efficialtec.com/storage/profileimages";

//export const API_URL = "http://127.0.0.1:8001/v1";
//export const PROFILE_IMAGE_BASE_URL = "http://127.0.0.1:8001/storage/profileimages";

export const request = (method, url, data = null, isNeedAuthorization = false) => {
    return Axios.request({
        method: method,
        url: API_URL + url,
        data: data,
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': isNeedAuthorization ?  `Bearer ${getToken()}` : null
        }
    })
}

export const getToken = () => {
    const user = getUser();
    console.log(user)
    return user && user.access_token;
}

export const getUser = () => {
    return Cookies.getJSON('user');
}
