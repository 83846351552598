import React, { Component } from 'react';
import LeftSidebar from '../../components/shared/left-sidebar';
import Header from '../../components/shared/header';
import Loader from '../../components/loader/loader';
import ReactTable from 'react-table';
import Pagination from '../../components/pagination/pagination';
import { Link } from 'react-router-dom';
import { request } from '../../constants/constants';
import UserContext from '../../contexts/user';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import moment from 'moment';
import dateIcon from '../../public/svg/date.svg';
import Swal from 'sweetalert2';


class EfficialtecIntegration extends Component {

	static contextType = UserContext;

	constructor(props) {
		super(props);
		this.state = {
			selected: {},
			pageSize: 10,
			currentPage: 1,
			selectedAll: {},
			indeterminateSign: false,
			leagues: [],
			isLoading: false
		}
		// this.handleButton = this.handleButton.bind(this);
		this.toggleRow = this.toggleRow.bind(this);
		// this.toggleSelectAll = this.toggleSelectAll.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
		this.handleThProps = this.handleThProps.bind(this);
	}

	componentDidMount() {
		this.setState({isLoading: true})
		this.getAllLeagues().then(res => {
			console.log(res);
			this.setState({isLoading: false})
			this.setState({
				leagues: res.data
			})
		})
	}

	getAllLeagues() {
		const orgId = this.context.user.current_org;
		return request('GET', `/auth/integrations/efficialtec/organisations/${orgId}/leagues`, null, true);
	}

	toggleSelectAll() {
		let newSelected = {};
		let newSelectPage;
		const start = (this.state.currentPage - 1) * this.state.pageSize;
		const end = start + this.state.pageSize;
		if (this.state.selectedAll[this.state.currentPage] === false || this.state.selectedAll[this.state.currentPage] == null || this.state.indeterminateSign === true) {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true});
			this.state.leagues.slice(start, end).forEach(data => {
				newSelected[data.id] = true;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			// const selectedLeagues = Object.keys(newObj).filter(obj => {
			// 	return newObj[obj] === true;
			// });
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage,
				indeterminateSign: false
			});
		} else {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage] : false });
			this.state.leagues.slice(start, end).forEach(data => {
				newSelected[data.id] = false;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			// const selectedGames = Object.keys(newObj).filter(obj => {
			// 	return newObj[obj] === true;
			// });

			// this.props.selectedGames(selectedGames);
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage
			});
		}
	}

	toggleRow(gameId) {
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[gameId] = !this.state.selected[gameId];

		const selectedGames = Object.keys(newSelected).filter(obj => {
			return newSelected[obj] === true;
		});

		// this.props.selectedGames(selectedGames);

		this.setState({
			selected: newSelected,
		});
	}

	handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				if (this.state.selectedAll[this.state.currentPage] === true) {
					this.setState({
						indeterminateSign: true
					})
				}
			});
		} else {
			this.setState({ pageSize: data }, () => {
				const start = (this.state.currentPage - 1) * this.state.pageSize;
				const end = start + this.state.pageSize;
				let newSelected = {};
				let newSelectPage;
				if (this.state.selectedAll[this.state.currentPage] === true) {
					newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
					this.props.games.forEach(data => {
						newSelected[data.id] = false;
					});
					this.props.games.slice(start, end).forEach(data => {
						newSelected[data.id] = true;
					});
				let newObj = Object.assign(this.state.selected, newSelected);
					const selectedList = Object.keys(newObj).filter(obj => {
						return newObj[obj] === true;
					});
					this.props.selectedList(selectedList);
					this.setState({
						selected: newObj,
						selectedAll: newSelectPage,
						indeterminateSign: false
					});
				}
			});
		}
	}

	handleClick = e => {
		this.setState({
			isCalendarShowed: true
		})
	}
	onStartDateChange = value => {
		this.setState({
			startDate: value
		})
	}
	onEndDateChange = value => {
		this.setState({
			endDate: value
		})
	}
	handleClearEndDate = () => {
		this.setState({
			endDate: undefined
		})
	}
	handleClearStartDate = () => {
		this.setState({
			startDate: undefined
		})
	}

	doImportGames = async () => {
		this.setState({isLoading: true})
		const startDate = this.state.startDate.format('YYYY-MM-DD');
		const endDate = this.state.endDate.format('YYYY-MM-DD');
		let league_ids = '';
		const selected = Object.keys(this.state.selected);
		selected.forEach(key => {
			if(this.state.selected[key]) {
				league_ids += `,${key}`
			}
		})
		console.log(startDate, endDate, league_ids.substring(1))
		request(
			'GET', 
			`/auth/integrations/efficialtec/organisations/${this.context.user.current_org}/games?start_date=${startDate}&end_date=${endDate}&league_ids=${league_ids.substring(1)}`,
			null,
			true
		).then(res => {
			console.log(res);
			this.setState({
				startDate: undefined,
				endDate: undefined,
				isLoading: false
			})
			Swal.fire(
				'Success',
				res.data.message,
				"success"
			)
		}).catch(err => {
			console.error(err.response);
			this.setState({isLoading: false})
			Swal.fire(
				'Error',
				err.response.data.message,
				"error"
			)
		})
	}

	handleSyncGames = e => {
		
	}

	render() {
		const data = this.state.leagues;
		const columns = [{
			Header: (header) => (
				<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
					<input
						type="checkbox"
						className="custom-control-input"
						id="selectAllCheckBox"
						checked={this.state.selectedAll[this.state.currentPage] || false}
						onChange={() => this.toggleSelectAll()}
						ref={input => {
							if (input) {
								input.indeterminate = this.state.indeterminateSign;
							}
						}}
					/>
					<label className="custom-control-label" htmlFor="selectAllCheckBox"></label>
				</div>
			),
			width: 52,
			Cell: row => (
				<div className="custom-control custom-checkbox" style={{ marginLeft: 15 }}>
					<input type="checkbox" className="custom-control-input" id={`game-id-${row.original.id}`} checked={this.state.selected[row.original.id] || false} onChange={() => this.toggleRow(row.original.id)} />
					<label className="custom-control-label" htmlFor={`game-id-${row.original.id}`}></label>
				</div>
			),
			className: 'header-checkbox-toggle',
			headerStyle: { padding: '1.07143em 0' },
			style: { padding: '0.71429em 0' }
		}, {
			Header: 'Name',
			accessor: 'league_name'
		}
		// , {
		// 	Header: 'Game Length',
        //     accessor: 'game_length',
		// }
	];
	const calendar = (<Calendar
		style={{ zIndex: 1000 }}
		dateInputPlaceholder="Select Date"
		formatter="YYYY-MM-DD"
		disabledTime={null}
		timePicker={null}
		defaultValue={moment()}
		showDateInput={false}
		showClear={true}
	/>);
		return (
			// <div>
			// 	<LeftSidebar />
			// 	<div className="main-panel">
			// 		<Header />
			// 		<div className="main-content">
			// 			<div className="container-fluid h-100">
							<div className="efficialtec-integration">
								{/* {this.props.loading && <Loader />} */}
								<div className="page-head mb_30">
									<div className="head">
										<h1 className="page-title">Efficialtec Leagues</h1>
										<nav>
											<ol className="breadcrumb page-title">
												<li className="breadcrumb-item"><Link to="/">Home</Link></li>
												<li className="breadcrumb-item current">All Leagues</li>
											</ol>
										</nav>
									</div>
								</div>		

								<div className="modal fade" id="dates">
										<div className="modal-dialog modal-dialog-centered modal-lg">
											<div className="modal-content">
												<div className="modal-header">
													<h5 className="modal-title">Please select Start Date and End Date</h5>
													<button type="button" className="close" data-dismiss="modal">
														<span>&times;</span>
													</button>
												</div>
												<div style={{ minHeight: this.state.isCalendarShowed ? '320px' : ''}} className="modal-body">
													<div className="row">
													<div className="col-xl-6 col-lg-6 col-md-6 col-12">
															<div className="form-group">
																<label className="label d-flex align-items-center justify-content-between">
																	<div>
																		Start Date
																	</div>
																	<button className="button-link" onClick={this.handleClearStartDate}>Clear</button>
																</label>
																<div className="input-effect-1">
																	<div className="datepicker" id="datepicker">
																		<DatePicker
																			animation="slide-up"
																			calendar={calendar}
																			value={this.state.startDate}
																			onChange={this.onStartDateChange}
																			getCalendarContainer={() => document.getElementById('datepicker')}
																			className="custom-calendar"
																			showClear={true}
																		>
																			{
																				({ value }) => {
																					return (
																						<div onClick={this.handleClick} className="input-group">
																							<input
																								placeholder="Select Date"
																								// disabled={(state.filters.chooseDate !== 'game-dates')}
																								readOnly
																								tabIndex="-1"
																								className="ant-calendar-picker-input ant-input form-control"
																								value={value == null ? '' : moment(value).format('YYYY-MM-DD')}
																							/>
																							<div className="input-group-append">
																								<span className="input-group-text">
																									<img src={dateIcon} alt="date" className="imgsvg" />
																								</span>
																							</div>
																						</div>
																					);
																				}
																			}
																		</DatePicker>
																	</div>
																	<div className="focus-border"></div>
																</div>
															</div>
														</div>
														<div className="col-xl-6 col-lg-6 col-md-6 col-12">
															<div className="form-group">
																<label className="label d-flex align-items-center justify-content-between">
																	<div>
																		End Date
																	</div>
																	<button className="button-link" onClick={this.handleClearEndDate}>Clear</button>
																</label>
																<div className="input-effect-1">
																	<div className="datepicker" id="endDate">
																		<DatePicker
																			animation="slide-up"
																			calendar={calendar}
																			value={this.state.endDate}
																			onChange={this.onEndDateChange}
																			getCalendarContainer={() => document.getElementById('endDate')}
																			className="custom-calendar"
																			showClear={true}
																		>
																			{
																				({ value }) => {
																					return (
																						<div className="input-group">
																							<input
																								placeholder="Select Date"
																								// disabled={(state.filters.chooseDate !== 'game-dates')}
																								readOnly
																								tabIndex="-1"
																								className="ant-calendar-picker-input ant-input form-control"
																								value={value == null ? '' : moment(value).format('YYYY-MM-DD')}
																							/>
																							<div className="input-group-append">
																								<span className="input-group-text">
																									<img src={dateIcon} alt="date" className="imgsvg" />
																								</span>
																							</div>
																						</div>
																					);
																				}
																			}
																		</DatePicker>
																	</div>
																	<div className="focus-border"></div>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="modal-footer text-center">
													<button data-dismiss="modal" type="button" className="btn btn-orange" disabled={this.state.isLoading} onClick={this.doImportGames}>
														{this.state.isLoading && <span  className="mr-2 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}	Import
													</button>
												</div>
											</div>
										</div>
									</div>					

								<div className="table-area">
									{(this.state.isLoading) && <Loader />}
									<ReactTable
										PaginationComponent={Pagination}
										data={data}
										minRows={5}
										columns={columns}
										noDataText={this.state.isLoading ? '' : "No Games Found."}
										defaultPageSize={this.state.pageSize}
										pageSizeOptions={[10, 25, 50, 100]}
										resizable={true}
										onPageChange={(index) => this.handlePageChange(index)}
										onPageSizeChange={(size) => this.handlePageSizeChange(size)}
										getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
									/>
								</div>

								<div className="actions mt-5 clearfix">
									<ul className="list-inline float-right">
										<li className="list-inline-item mb_15">
											<button onClick={this.handleSyncGames} className="btn btn-orange"  >Sync Games</button>
										</li>
										<li className="list-inline-item mb_15">
											<button data-toggle="modal" data-target="#dates" className="btn btn-orange"  >Import Games</button>
										</li>
									</ul>
								</div>
							</div>
			// 			</div>
			// 		</div>
			// 	</div>
			// </div>
		);
	}
}
export default EfficialtecIntegration;