import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Pagination from '../../../../components/pagination/pagination';
import moment from 'moment';
import { Link } from 'react-router-dom';
import ListReferee from './list_referee/list_referee';
import classNames from 'classnames';
import './game_results.scss';
import Loader from '../../../../components/loader/loader';
import Swal from 'sweetalert2';
import UserContext from '../../../../contexts/user';
import { request } from '../../../../constants/constants';

export default class GameResults extends Component {
	
	static contextType = UserContext;

	constructor(props) {
		super(props);
		console.log(this.props)
		this.state = {
			expanded: {},
			isExpandedAll: false,
			selected: {},
			pageSize: 10,
			currentPage: 1,
			selectedAll: {},
			indeterminateSign: false
		}
		// this.handleDelete = this.handleDelete.bind(this);
		this.genderName = this.genderName.bind(this);
		this.positionName = this.positionName.bind(this);
		this.statusColor = this.statusColor.bind(this);
		this.handleRowExpanded = this.handleRowExpanded.bind(this);
		this.handleThProps = this.handleThProps.bind(this);
		this.toggleRow = this.toggleRow.bind(this);
		this.toggleSelectAll = this.toggleSelectAll.bind(this);
		this.handlePageChange = this.handlePageChange.bind(this);
		this.handlePageSizeChange = this.handlePageSizeChange.bind(this);
	}

	componentWillReceiveProps(nextProps) {
		// let selected = {}
		// nextProps.games.forEach(data => {
		// 	selected[data.id] = false;
		// });

		// this.setState({
		// 	selected: selected
		// });
	}

	handleDelete = (id) => e => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.value) {
				this.setState({
					deleteVideoLoading: true,
				})
				request(
					'DELETE',
					`/auth/organisation/${this.context.user.current_org}/games/${id}/video`,
					{},
					true
				).then(res => {
					console.log(res)
					this.setState({
						deleteVideoLoading: false,
					})
					Swal.fire(
						'Success',
						res.data.message,
						'success'
					)
					console.log(this.props)
					this.props.init();
				}).catch(err => {
					console.error(err);
					this.setState({
						deleteVideoLoading: false,
					})
					Swal.fire(
						'Error',
						err.response.data.message,
						'error'
					)
				})
			}
		})
	}

	genderName(id) {
		switch (id) {
			case 0: return 'NULL';
			case 1: return 'MEN';
			case 2: return 'WOMEN';
			case 3: return 'BOYS';
			case 4: return 'GIRLS';
			case 5: return 'MIXED';
			default: return '';
		}
	}

	positionName(name) {
		return name.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '');
	}

	statusColor(status) {
		switch (status) {
			case "Pending": return 'yellow'
			case "Confirmed": return 'green'
			case "Declined": return 'red'
			case "Removed": return 'red'
			default: return ''
		}
	}

	handleRowExpanded(newExpanded, index, event) {
		this.setState({
			expanded: newExpanded
		})
	}

	handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.className === "header-expand-toggle") {
					if (this.state.isExpandedAll === false) {
						let ObjExpand = {}
						for (let x = 0; x < state.pageSize; x++) {
							ObjExpand[x] = {};
						}
						this.setState({
							expanded: ObjExpand,
							isExpandedAll: true
						})
					} else if (this.state.isExpandedAll) {
						let ObjExpand = {}
						for (let x = 0; x < state.pageSize; x++) {
							ObjExpand[x] = false;
						}
						this.setState({
							expanded: ObjExpand,
							isExpandedAll: false
						})
					}
				}

				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	toggleRow(gameId) {
		const newSelected = Object.assign({}, this.state.selected);
		newSelected[gameId] = !this.state.selected[gameId];

		const selectedGames = Object.keys(newSelected).filter(obj => {
			return newSelected[obj] === true;
		});

		this.props.selectedGames(selectedGames);

		this.setState({
			selected: newSelected,
		});
	}

	toggleSelectAll() {
		let newSelected = {};
		let newSelectPage;
		const start = (this.state.currentPage - 1) * this.state.pageSize;
		const end = start + this.state.pageSize;
		if (this.state.selectedAll[this.state.currentPage] === false || this.state.selectedAll[this.state.currentPage] == null || this.state.indeterminateSign === true) {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true});
			this.props.games.slice(start, end).forEach(data => {
				newSelected[data.id] = true;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			const selectedGames = Object.keys(newObj).filter(obj => {
				return newObj[obj] === true;
			});
			this.props.selectedGames(selectedGames);
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage,
				indeterminateSign: false
			});
		} else {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage] : false });
			this.props.games.slice(start, end).forEach(data => {
				newSelected[data.id] = false;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			const selectedGames = Object.keys(newObj).filter(obj => {
				return newObj[obj] === true;
			});

			this.props.selectedGames(selectedGames);
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage
			});
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				if (this.state.selectedAll[this.state.currentPage] === true) {
					this.setState({
						indeterminateSign: true
					})
				}
			});
		} else {
			this.setState({ pageSize: data }, () => {
				const start = (this.state.currentPage - 1) * this.state.pageSize;
				const end = start + this.state.pageSize;
				let newSelected = {};
				let newSelectPage;
				if (this.state.selectedAll[this.state.currentPage] === true) {
					newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
					this.props.games.forEach(data => {
						newSelected[data.id] = false;
					});
					this.props.games.slice(start, end).forEach(data => {
						newSelected[data.id] = true;
					});
					let newObj = Object.assign(this.state.selected, newSelected);
					const selectedGames = Object.keys(newObj).filter(obj => {
						return newObj[obj] === true;
					});
					this.props.selectedGames(selectedGames);
					this.setState({
						selected: newObj,
						selectedAll: newSelectPage,
						indeterminateSign: false
					});
				}
			});
		}
	}

	render() {
		const data = this.props.games;
		const columns = [

		{
			Header: 'Match',
			minWidth: 250,
			Cell: row => (
				<div className="match">
					<span className="highlight">{row.original.home_team && row.original.home_team.team_name} vs {row.original.away_team && row.original.away_team.team_name}</span>
					<div className="date-time">
						<ul className="list-inline">
							<li className="list-inline-item"><span className="date">{moment(row.original.game_date).format("MMM DD, YYYY")}</span></li>
							<li className="list-inline-item"><span className="seprator">/</span></li>
							<li className="list-inline-item"><span className="time">{moment(row.original.game_time, "HH:mm").format("LT")}</span></li>
							<li className="list-inline-item"><span className="badge badge-upcoming" style={{ backgroundColor: `${row.original.video ? '#90EE90' : '#fff700de'}`}}>{ row.original.video ? 'Video Uploaded' : 'Awaiting Upload' }</span></li>
						</ul>
					</div>
					<div className="actions">
						<ul className="list-inline">
							{/* <li>
								<button onClick={this.props.download(row.original.id)} className="link">Download</button>
							</li>
							<li><span className="seprator">|</span></li> */}
							{/* <li>
							<button onClick={this.props.showModal(row.original.id)} className="link">Upload</button>
							</li> */}
							{/* <li><span className="seprator">|</span></li> */}
							<li>
								<Link to={`/game/${row.original.id}/detail`}><button className="link">View</button></Link>
							</li>
							<li><span className="seprator">|</span></li>
							{
								row.original.video && (
									<React.Fragment>
										<li>
											<button onClick={this.props.download(row.original.id)} className="link">Download</button>
										</li>
										<li><span className="seprator">|</span></li>
										<li>
											<button onClick={this.handleDelete(row.original.id)} className="link">Delete</button>
										</li>
									</React.Fragment>
								)
							}
						
						</ul>
					</div>
				</div>
			)
		}, {
			Header: 'Venue',
			Cell: row => (
				<div className="date-time">
					{row.original.venue ? row.original.venue.venue_name : ''}
				</div>
			)
		}, {
			Header: 'League',
			Cell: row => (
				<div className="date-time">
					{row.original.league != null ? row.original.league.league_name : ''}
				</div>
			)
		}, {
			Header: 'Gender',
			Cell: row => (
				<div className="date-time">
					{this.genderName(row.original.gender)}
				</div>
			)
		}];
		return (
			<div className="table-area">
				{(this.props.loading || this.props.fetchLoading) && <Loader />}
				<ReactTable
					PaginationComponent={Pagination}
					data={data}
					minRows={5}
					columns={columns}
					noDataText={this.props.loading ? '' : "No Games Found."} 
					defaultPageSize={this.state.pageSize}
					pageSizeOptions={[10, 25, 50, 100]}
					resizable={true}
					expanded={this.state.expanded}
					onExpandedChange={(newExpanded, index, event) => this.handleRowExpanded(newExpanded, index, event)}
					getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
					onPageChange={(index) => this.handlePageChange(index)}
					onPageSizeChange={(size) => this.handlePageSizeChange(size) }
					// SubComponent={row => {
					// 	console.log(row)
					// 	// return (
					// 	// 	// <div style={{ paddingLeft: 100 }} className="game-list">
					// 	// 	// 	<ul className="list-inline">
					// 	// 	// 		{row.original.fields.map((field) => (
					// 	// 	// 			<li key={field.id}>
					// 	// 	// 				<div className={classNames('status', this.statusColor(typeof row.original.referees[field.id] === "object" ? row.original.referees[field.id].status : ''))}></div>
					// 	// 	// 				<div className="name">{typeof row.original.referees[field.id] === "object" ? row.original.referees[field.id].official.name : row.original.referees[field.id]}</div>
					// 	// 	// 				<div className="post">({this.positionName(field.field_name)})</div>
					// 	// 	// 			</li>
					// 	// 	// 		))}
					// 	// 	// 	</ul>
					// 	// 	// </div>
					// 	// );
					// }}
				/>
			</div>
		);
	}
}

// GameResults.propTypes = {
// 	removeGames: PropTypes.func.isRequired,
// 	loading: PropTypes.bool
// }