import React, { Component } from 'react';
import LeftSidebar from '../../../components/shared/left-sidebar';
import Header from '../../../components/shared/header';
import { Link } from 'react-router-dom';
import { request } from '../../../constants/constants';
import moment from 'moment';
import UserContext from '../../../contexts/user';
import './teamDetail.scss';

class TeamDetail extends Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            gender: [
				'Men',
				'Women',
				'Boys',
				'Girls',
				'Mixed'
			],
			teamDetails: {}
        }
    }

	componentDidMount() {
		this.getteamDetails().then(res => {
			console.log(res);
			this.setState({
				teamDetails: res.data
			})
		})
	}

	getteamDetails() {
		return request(
			'GET',
			`/auth/organisation/${this.context.user.current_org}/teams/${this.props.match.params.id}`,
			{},
			true
		)
	}
    
    render() {
        const latitude = -35.2421201;
		const longitude = 149.0572644;
		return (
			// <div>
			// 	<LeftSidebar />
			// 	<div className="main-panel">
			// 		<Header />
			// 		<div className="main-content">
			// 			<div className="container-fluid h-100">
							<div className="team-detail">
								{/* {this.props.loading && <Loader />} */}
								<div className="page-head mb_30">
									<div className="head">
										<h1 className="page-title">{this.state.teamDetails.team_name}</h1>
										<nav>
											<ol className="breadcrumb page-title">
												<li className="breadcrumb-item"><Link to="/">Home</Link></li>
												<li className="breadcrumb-item"><Link to="/teams">Teams</Link></li>
												<li className="breadcrumb-item current">{this.state.teamDetails.team_name}</li>
											</ol>
										</nav>
									</div>
									<div className="actions">
										<Link to="/teams"><button className="btn btn-white mr_15">Back to all Teams</button></Link>
										{/* <Link to={`/game/edit/${this.props.match.params.id}`}><button className="btn btn-orange">Edit Game</button></Link> */}
									</div>
								</div>
								<div className="box">
									<div className="team mb_30">
										<div className="row">
											<div className="col-xl-6 col-lg-6 col-md-12 col-12 mb-sm-30 mb-md-30">
												<table className="table-data">
													<thead>
														<tr>
															<th width="30%">League</th>
															<td width="70%">{this.state.teamDetails.league && this.state.teamDetails.league.league_name}</td>
														</tr>
														{/* <tr>
															<th>Date / Time</th>
															<td>{this.state.teamDetails.game && `${moment(this.state.teamDetails.game.game_date).format("MMM DD, YYYY")} /`} {this.state.teamDetails.game && moment(this.state.teamDetails.game.game_time, "HH:mm").format("LT")}</td>
														</tr> */}
														<tr>
															<th>Venue</th>
															<td>{this.state.teamDetails.venue && this.state.teamDetails.venue.venue_name}</td>
														</tr>
														<tr>
															<th>Gender</th>
															<td>{this.state.teamDetails.gender && this.state.gender[this.state.teamDetails.gender]}</td>
														</tr>
														{/* <tr>
															<th>Pattern</th>
															<td>{this.state.teamDetails.pattern && this.state.teamDetails.pattern.pattern_name}</td>
														</tr> */}
													</thead>
												</table>
											</div>
											<div className="col-xl-6 col-lg-6 col-md-12 col-12">
												<div className="map">
													{/* <iframe width="100%" height="450" frameborder="0" style="border:0" src="https://www.google.com/maps/embed/v1/place?q=40.7127837,-74.0059413&amp;key=YOUR_API_KEY"></iframe> */}
													<iframe title="map" src={`//maps.google.com/maps?q=${latitude}, ${longitude}&z=15&output=embed`} width="360" height="270" frameBorder="0" style={{ border: 0 }}></iframe>
													{/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4424751.652531875!2d130.68192050976077!3d-25.25529845479727!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2b2bfd076787c5df%3A0x538267a1955b1352!2sAustralia!5e0!3m2!1sen!2sin!4v1543322921710" title="Venue Location"></iframe> */}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
			// 			</div>
			// 		</div>
			// 	</div>
			// </div>
		);
    }
}

export default TeamDetail;