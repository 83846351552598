import React, { Component } from 'react';
import ReactTable from 'react-table';
import Loader from '../../components/loader/loader';
import { Link } from 'react-router-dom';
import LeftSidebar from '../../components/shared/left-sidebar';
import Header from '../../components/shared/header';
import UserContext from '../../contexts/user';
import { request } from '../../constants/constants';
import Pagination from '../../components/pagination/pagination';

class Venues extends Component {

    static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            selected: {},
			pageSize: 10,
			currentPage: 1,
			selectedAll: {},
			indeterminateSign: false,
			venues: [],
			isLoading: false
        }
    }
    

    handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				if (this.state.selectedAll[this.state.currentPage] === true) {
					this.setState({
						indeterminateSign: true
					})
				}
			});
		} else {
			this.setState({ pageSize: data }, () => {
				const start = (this.state.currentPage - 1) * this.state.pageSize;
				const end = start + this.state.pageSize;
				let newSelected = {};
				let newSelectPage;
				if (this.state.selectedAll[this.state.currentPage] === true) {
					newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
					this.state.venues.forEach(data => {
						newSelected[data.id] = false;
					});
					this.state.venues.slice(start, end).forEach(data => {
						newSelected[data.id] = true;
					});
					let newObj = Object.assign(this.state.selected, newSelected);
					const selectedList = Object.keys(newObj).filter(obj => {
						return newObj[obj] === true;
					});
					// this.props.selectedList(selectedList);
					this.setState({
						selected: newObj,
						selectedAll: newSelectPage,
						indeterminateSign: false
					});
				}
			});
		}
	}

	componentDidMount() {
		this.setState({isLoading: true})
		this.getAllVenues().then(res => {
			console.log(res);
			this.setState({
				venues: res.data,
				isLoading: false
			})
		}).catch(err => {
			console.error(err.response.message);
			this.setState({isLoading: false})
		})
	}

	getAllVenues() {
		return request(
			'GET',
			`/auth/organisation/${this.context.user.current_org}/venues`,
			{},
			true
		)
    }
    
    render() {
        const data = this.state.venues;
		const columns = [{
            Header: 'Name',
			accessor: 'venue_name'
        }];
        return (
            // <div>
            // <LeftSidebar />
            // <div className="main-panel">
            //     <Header />
            //     <div className="main-content">
            //         <div className="container-fluid h-100">
                        <div className="efficialtec-integration">
                            <div className="page-head mb_30">
                                <div className="head">
                                    <h1 className="page-title">Venues</h1>
                                    <nav>
                                        <ol className="breadcrumb page-title">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item current">Venues</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>		

                            <div className="table-area">
                                {this.state.isLoading && <Loader />}
                                <ReactTable
                                    PaginationComponent={Pagination}
                                    data={data}
                                    minRows={5}
                                    columns={columns}
                                    noDataText={this.state.isLoading ? '' : "No Venues Found."}
                                    defaultPageSize={this.state.pageSize}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    resizable={true}
                                    onPageChange={(index) => this.handlePageChange(index)}
                                    onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                                    getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                                />
                            </div>
                        </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        );
    }
}

export default Venues;