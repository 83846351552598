import React, {Component} from 'react';
// import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import dateIcon from '../../../public/svg/date.svg';
import moment from 'moment';
import Calendar from 'rc-calendar';
import DatePicker from 'rc-calendar/lib/Picker';
import 'rc-calendar/assets/index.css';
import Modal from 'react-responsive-modal';
import ResetPassword from '../reset-password/form';
import BankForm from './bank_form';
import Loader from '../../../components/loader/loader';
import FormError from '../../../components/form-error/form-error';
const format = 'YYYY-MM-DD';
const now = moment();
const defaultCalendarValue = now.clone();
defaultCalendarValue.add(-1, 'month');

export default class EditForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
           user: {
            email: '',
            name: '',
           },
           errors: {

           }
        }
    }
    componentWillMount () {
        // let user = JSON.parse(localStorage.getItem('user'));
        // let user_id = user.user.id;
        // this.props.fetchOfficial(user_id);
    }

    componentWillReceiveProps(nextProps) {
        // if(Object.keys(nextProps.official).length > 0)
        // {
        //     let date_of_birth = now;
        //     if(nextProps.official.date_of_birth)
        //     {
        //         date_of_birth = moment(nextProps.official.date_of_birth);
        //     }
        //     this.setState({
        //         email: nextProps.official.email,
        //         name: nextProps.official.name ? nextProps.official.name : '',
        //         address_line_1: nextProps.official.address_line_1 ? nextProps.official.address_line_1 : '',
        //         mobile: nextProps.official.mobile ? nextProps.official.mobile : '',
        //         date_of_birth: date_of_birth
        //     });
        // }
        // if(nextProps.bank_form_success === true)
        // {
        //     this.onCloseBankModal();
        // 
        console.log(this.props)
     
    }

    componentDidMount() {
        console.log(this.props)
        this.setState({
            user: this.props.user
        })
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            user: {
                ...this.state.user,
                [name]: value,
            }
        });
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const validEmailRegex = RegExp(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i);
		let errors = {};
        const data = {
            name: this.state.user.name,
            email: this.state.user.email
        }
        if(!validEmailRegex.test(data.email)) {
            errors.email = 'Email is invalid'
        }
        if(!data.email) {
            errors.email = 'Email is required'
        }
        if(!data.name) {
            errors.name = 'Name is required'
        }
        this.setState({
            errors: errors
        })
        this.props.handleSubmit(data);
    }

    handleCancel = (event) => {
        event.preventDefault();
        this.props.handleCancel();
    }

    render () {
        let name_error = null;
        let email_error= null;

        if(this.state.errors !== undefined)
        {
            for (var key in this.state.errors) {
                if(key === 'name')
                    name_error = <FormError error={this.state.errors[key]} />;
                else if(key === 'email')
                    email_error = <FormError error={this.state.errors[key]} />;
            }
        }
        return (
            <div className="col-xl-9 col-lg-7 col-md-12 col-12">
                {this.props.loading && <Loader />}
                <form onSubmit={this.handleSubmit} className="row mb_15">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="label">Full Name</label>
                                <div className="input-effect-1">
                                    <input name="name" type="text" value={this.state.user.name} onChange={this.handleInputChange} className="form-control" />
                                    <span className="focus-border"></span>
                                </div>
                            </div>
                            {name_error}
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label className="label">Email</label>
                                <div className="input-effect-1">
                                    <input type="text" name="email" value={this.state.user.email} onChange={this.handleInputChange} className="form-control" />
                                    <span className="focus-border"></span>
                                </div>
                                <div className="custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" name="share-email" id="share-email" />
                                    {/* <label className="custom-control-label" htmlFor="share-email">
                                        Share my email with other officials
                                    </label> */}
                                </div>
                            </div>
                            {email_error}
                        </div>
                        {/* <div className="col-md-6 mt-5">
                            <button className="btn btn-orange btn-forgot mb-sm-15 mr_20" onClick={this.onOpenResetModal}>Reset Password</button>
                        </div> */}
                        <div className="col-md-6 mt-5">
                            <button className="btn btn-white mr_20" onClick={this.handleCancel}>Cancel</button>
                            <button type="submit" className="btn btn-orange" >
                            {
                                this.props.isLoading ? (
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                ) : 'Save'
                            }
                            </button>
                        </div>
                </form>
            </div>
        );
    }
}

// EditForm.propTypes = {
//     fetchOfficial: PropTypes.func.isRequired,
//     official: PropTypes.object.isRequired,
//     loading: PropTypes.bool,
//     onSubmitOfficial: PropTypes.func.isRequired,
//     errors: PropTypes.object,
//     form_success: PropTypes.bool,
//     bank_form_success: PropTypes.bool,
//     bankEditStatus: PropTypes.func.isRequired
// }