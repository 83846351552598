import React, { Component } from 'react';
import LogoBackground from '../../components/logo/LogoBackground';
import Logo from '../../components/logo/Logo';
import LoginForm from '../../components/login-form/LoginForm';
import './Login.scss';
import '../../public/styles/authentication.scss';
import { request } from '../../constants/constants';
import history from '../../history';
import Cookies from 'js-cookie';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            errors: {}
        }
    }
    
    render() {
        return (
            <div className="authentication">
                <LogoBackground />
                <div className="login-container">
                    <div className="login-wrap">
                        <Logo />
                        <LoginForm alert_error_show={this.state.alert_error_show} alert_error_msg={this.state.alert_error_msg} errors={this.state.errors} loading={this.state.isLoading} onLoginSubmitted={this.onLoginSubmitted}/>
                    </div>
                </div>
            </div>
        );
    }

    onLoginSubmitted = (data) => {
        console.log(data)
        this.setState({ isLoading: true });
        const validationResult = this.doValidation(data);
        request('POST', '/auth/login', data).then(result => {
            console.log(result);
            this.setState({ 
                errors:  validationResult.errors,
                isLoading: false
            });
            Cookies.set('user', result.data)
            window.location.href = "/"

        }).catch(err => {
            console.log(err.response);
            const alert_error_msg =  err.response.status == 401 ? 'Invalid email or password' : err.response.data.message
            this.setState({ 
                errors:  validationResult.errors,
                isLoading: false,
                alert_error_msg: alert_error_msg,
                alert_error_show: true
            });
        })
    }

    doValidation(data) {
        let errors = {};
        if(!data.email) {
            errors.email = 'The email field is required.';
        }
        if(!data.password) {
            errors.password = 'The password field is required.';
        }
        return { errors };
    }
}

export default Login;