import React, { Component } from 'react';
import emailSvg from '../../public/svg/email.svg';
import { Link } from 'react-router-dom';
import Loader from '../../components/loader/loader';
import AlertError from '../../components/alert/error';
import FormError from '../../components/form-error/form-error';

function ForgotPassForm({ loading, onSubmit, errors, alert_error_msg, alert_error_show, route_error }) {
        let email_field = null;
        let password_field = null;
        const handleSubmit = (e) => {
			e.preventDefault();
			
            let data = {
                email: email_field.value,
            }
            onSubmit(data);
        }

        let login_loader = null;
        if (loading === true) {
            login_loader = <Loader />;
        }

        let alert_error = null;
        if (alert_error_show === true) {
            alert_error = <AlertError msg={alert_error_msg} />
        }

        let email_error = null;
        let password_error = null;
        if (errors !== undefined) {
            for (var key in errors) {
                if (key === 'email')
                    email_error = <FormError error={errors[key]} />;
                else
                    password_error = <FormError error={errors[key]} />;
            }
        }

    
        return (
            <form onSubmit={handleSubmit} method="POST">
                {loading && <Loader />}
                <div className="signin">
                    <label className="label">Forgot Password</label>
                    <div className="form-group">
                        <div className="input-group">
                            <div className="input-group-prepend">
                                <span className="input-group-text">
                                    <span className="icon icon-email"><img src={emailSvg} className="imgsvg" alt="email" /></span>
                                </span>
                            </div>
                            <input type="email" name="email" ref={(e) => email_field = e}  className="form-control" placeholder="Email" />
                        </div>
                        {email_error}
                    </div>
                </div>
                <div className="action"> 
                    <input type="submit" name="forgot" value="Send" className="submit"  />
                    <div className="signup">
                        <Link to="/login">Login</Link> <span>|</span>
                        <Link to="/register">Register</Link>
                    </div>
                </div>
            </form>
        );

}

export default ForgotPassForm;