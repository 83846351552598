import React, { Component } from 'react';
import LeftSidebar from '../../components/shared/left-sidebar';
import Header from '../../components/shared/header';
import { Link } from 'react-router-dom';
import ReactTable from 'react-table';
import Loader from '../../components/loader/loader';
import Pagination from '../../components/pagination/pagination';
import { request } from '../../constants/constants';
import UserContext from '../../contexts/user';

class Teams extends Component {

	static contextType = UserContext;

    constructor(props) {
        super(props);
        this.state = {
            selected: {},
			pageSize: 10,
			currentPage: 1,
			selectedAll: {},
			indeterminateSign: false,
			teams: [],
			isLoading: false,
			gender: [
				'Men',
				'Women',
				'Boys',
				'Girls',
				'Mixed'
			],
        }
        
	}
	
	componentDidMount() {
		this.setState({isLoading: true});
		this.getAllTeams().then(res => {
			this.setState({
				isLoading: false,
				teams: res.data.data
			});
			console.log(res);
		}).catch(err => {
			console.error(err);
			this.setState({isLoading: false});
		})
	}

	getAllTeams() {
		return request(
			'GET',
			`/auth/organisation/${this.context.user.current_org}/teams`,
			{},
			true
		)
	}

    toggleSelectAll() {
		let newSelected = {};
		let newSelectPage;
		const start = (this.state.currentPage - 1) * this.state.pageSize;
		const end = start + this.state.pageSize;
		if (this.state.selectedAll[this.state.currentPage] === false || this.state.selectedAll[this.state.currentPage] == null || this.state.indeterminateSign === true) {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true});
			this.state.teams.slice(start, end).forEach(data => {
				newSelected[data.id] = true;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			// const selectedLeagues = Object.keys(newObj).filter(obj => {
			// 	return newObj[obj] === true;
			// });
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage,
				indeterminateSign: false
			});
		} else {
			newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage] : false });
			this.state.teams.slice(start, end).forEach(data => {
				newSelected[data.id] = false;
			});
			let newObj = Object.assign(this.state.selected, newSelected);
			// const selectedGames = Object.keys(newObj).filter(obj => {
			// 	return newObj[obj] === true;
			// });

			// this.props.selectedGames(selectedGames);
			this.setState({
				selected: newObj,
				selectedAll: newSelectPage
			});
		}
	}

	handleThProps(state, rowInfo, column, instance) {
		return {
			onClick: (e) => {
				if (column.sortable !== false) {
					if (column.className !== 'header-checkbox-toggle') {
						instance.sortColumn(column)
					}
				}
			}
		}
	}

	handlePageChange(data) {
		this.setState({ currentPage: data + 1 });
	}

	handlePageSizeChange(data) {
		if (this.state.pageSize < data) {
			this.setState({ pageSize: data }, () => {
				if (this.state.selectedAll[this.state.currentPage] === true) {
					this.setState({
						indeterminateSign: true
					})
				}
			});
		} else {
			this.setState({ pageSize: data }, () => {
				const start = (this.state.currentPage - 1) * this.state.pageSize;
				const end = start + this.state.pageSize;
				let newSelected = {};
				let newSelectPage;
				if (this.state.selectedAll[this.state.currentPage] === true) {
					newSelectPage = Object.assign(this.state.selectedAll, { [this.state.currentPage]: true });
					this.state.teams.forEach(data => {
						newSelected[data.id] = false;
					});
					this.state.teams.slice(start, end).forEach(data => {
						newSelected[data.id] = true;
					});
					let newObj = Object.assign(this.state.selected, newSelected);
					const selectedList = Object.keys(newObj).filter(obj => {
						return newObj[obj] === true;
					});
					// this.props.selectedList(selectedList);
					this.setState({
						selected: newObj,
						selectedAll: newSelectPage,
						indeterminateSign: false
					});
				}
			});
        }
    }
    
    render() {
        const data = this.state.teams;
		const columns = [
		{
			Header: 'Name',
			Cell: row => {
				return (
				<div className="match">
					<span className="highlight">{row.original.team_name}</span>
					<div className="actions">
						<ul className="list-inline">
							<li>
								<Link to={`/team/edit/${row.original.id}`}><button className="link">Edit</button></Link>
							</li>
							<li><span className="seprator">|</span></li>
							<li>
								<Link to={`/team/${row.original.id}/detail`}><button className="link">View</button></Link>
							</li>
							{/* <li><span className="seprator">|</span></li> */}
							{/* <li>
								<button onClick={() => this.handleDelete(row.original.id)} className="link">Delete</button>
							</li> */}
						</ul>
					</div>
				</div>
				)
			}
		},
		{
			Header: 'League',
			Cell: row => (
				<span>{row.original.league && row.original.league.league_name}</span>
			),
		},
		{
			Header: 'Gender',
			Cell: row => (
				<span>{row.original.gender && this.state.gender[row.original.gender]}</span>
			),
		},
		
	]
        return (
            // <div>
            // <LeftSidebar />
            // <div className="main-panel">
            //     <Header />
            //     <div className="main-content">
            //         <div className="container-fluid h-100">
                        <div className="efficialtec-integration">
                            {/* {this.props.loading && <Loader />} */}
                            <div className="page-head mb_30">
                                <div className="head">
                                    <h1 className="page-title">Teams</h1>
                                    <nav>
                                        <ol className="breadcrumb page-title">
                                            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                            <li className="breadcrumb-item current">Teams</li>
                                        </ol>
                                    </nav>
                                </div>
                            </div>		

                            <div className="table-area">
                                {(this.state.isLoading) && <Loader />}
                                <ReactTable
                                    PaginationComponent={Pagination}
                                    data={data}
                                    minRows={5}
                                    columns={columns}
                                    noDataText={this.state.isLoading ? '' : "No Teams Found."}
                                    // defaultPageSize={this.state.pageSize}
                                    pageSizeOptions={[10, 25, 50, 100]}
                                    resizable={true}
                                    // onPageChange={(index) => this.handlePageChange(index)}
                                    // onPageSizeChange={(size) => this.handlePageSizeChange(size)}
                                    // getTheadThProps={(state, rowInfo, column, instance) => this.handleThProps(state, rowInfo, column, instance)}
                                />
                            </div>
                        </div>
        //             </div>
        //         </div>
        //     </div>
        // </div>
        );
    }
}

export default Teams;