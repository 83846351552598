import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import Login from '../pages/login/Login';
import Register from '../pages/register/register';
import Confirm from '../pages/confirm/confirm';
import Dashboard from '../pages/dashboard/dashboard';
import EditProfilePage from '../pages/edit-profile/edit-profile';
import GameListPage from '../pages/game/game_list/game_list';
import IntegrationsPage from '../pages/integrations/integrations';
import efiicialTechCallback from '../pages/efficialtech-callback/efiicialTechCallback';
import EfficialtecIntegrationPage from '../pages/efficialtec-integration/efficialtecIntegration';
import ProtectedRoute from '../components/protected-route/protectedRoute';
import Leagues from '../pages/leagues/leagues';
import Teams from '../pages/teams/teams';
import GameDetail from '../pages/game/game_detail/game_detail';
import TeamDetail from '../pages/teams/team-detail/teamDetail';
import Venues from '../pages/venues/venues';
import InviteUsers from '../components/invite-users/inviteUsers';
import EditUser from '../pages/edit-user/editUser';
import AcceptInviteUser from '../pages/accept-invite-user/acceptInviteUser';
import NewUser from '../pages/new-user/newUser';
import PendingUsers from '../pages/pending-users/pendingUsers';
import Download from '../pages/download/donwload';
import ForgotPassword from '../pages/forgot-password/forgotPassword';
import ResetPassword from '../pages/reset-password/resetPassword';

function Routes() {
        return (
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/forgot-password" component={ForgotPassword} />
                <Route path="/reset" component={ResetPassword} />
                <Route path="/register" component={Register} />
                <Route path="/confirm" component={Confirm} />
                <Route path="/accept" component={AcceptInviteUser} /> 
                <ProtectedRoute path="/oauth/callback" component={efiicialTechCallback} />
                <ProtectedRoute exact path="/" component={Dashboard} />
                <ProtectedRoute path="/edit/profile" component={EditProfilePage} />
                <ProtectedRoute path="/games" component={GameListPage} />
                <ProtectedRoute path="/game/:id/detail" component={GameDetail} />
                <ProtectedRoute path="/game/:id/download" component={Download} />
                <ProtectedRoute path="/integrations" exact component={IntegrationsPage} />
                <ProtectedRoute path="/integrations/efficialtec" component={EfficialtecIntegrationPage} />
                <ProtectedRoute path="/leagues" component={Leagues} />
                <ProtectedRoute path="/teams" component={Teams} />
                <ProtectedRoute path="/team/:id/detail" component={TeamDetail} />
                <ProtectedRoute path="/venues" component={Venues} />
                <ProtectedRoute exact  path="/users" component={InviteUsers} />
                <ProtectedRoute exact  path="/users/pending" component={PendingUsers} />
                <ProtectedRoute exact  path="/users/invite" component={NewUser} />
                <ProtectedRoute path="/users/edit/:id" component={EditUser} />


            </Switch>
        );
}

export default Routes;